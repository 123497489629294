import React from "react"
import "./LoadingIcon.css"

function LoadingIcon ({title, icon}) {

    return (
        <div className="loading-icon-main">
            <div className="loading-icon-cont">
                <div className="loading-download-icon">
                    {icon}
                </div>
            </div>
            <div className="processing-info-cont">
                <div className="export-processing-title">{title}...</div>
                <div className="export-processing-time-info">This may take a few minutes</div>
            </div>
        </div>
    )
}

export default LoadingIcon