import "./Login.css";
import React, {useState} from "react";
import { FiLock } from "react-icons/fi";
import { HiOutlineUser } from "react-icons/hi2";
import {useLoginSession} from "../../stores/loginSession";
import Page from "../../components/Page";
import {Helmet} from "react-helmet-async";
import Config from "../../utility/Config";
import LoadingScreen from "../../components/LoadingScreen";
import {useGetLeagueLogo} from "../../utility/useGetLeagueLogo";
import {useNavigate} from "react-router-dom";
import classNames from "classnames";

export default function Login () {
    
    const {loginIsLoading, error, logIn} = useLoginSession();
    const baseName = window.location.pathname.split("/")[1];
    const isSef = Config.association === "SEF"

    const [mamCredentialLogin, setMamCredentialLogin] = useState(isSef ? false : true)
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [associationLogo, ,displayName] = useGetLeagueLogo(baseName)
    const navigate = useNavigate();

    function onLogin (e) {
        e.preventDefault();
        logIn(username, password);
        return false;
    }

    function onClickFotbollkonto () {
        const {pathname, search} = window.location;
        const locationState = encodeURIComponent(pathname + search);
        const ssoAuthorizePath = "/user/fotbollkonto/authorize?state=" + locationState;
        window.location = Config.getBackend() + ssoAuthorizePath;
    }

    function toPasswordReset () {
        navigate("/requestreset")
    }

    function handleMamCredentialLogin () {
        if (!isSef) return
        else setMamCredentialLogin(!mamCredentialLogin)
    }

    if (loginIsLoading) return (
        <Page centered>
            <Helmet>
                <title>Login</title>
            </Helmet>
            <div className="login-container flex-centered">
                <LoadingScreen />
            </div>
        </Page>
    )

    let mamName = "MAM"
    if (isSef) mamName = "Admin"

    const loginScreenLogoAndName = (
        <div className="login-logo-and-name">
            <img src={associationLogo} className="login-association-logo" alt="logo"/>
            <div className="login-association-name">{displayName}</div>
        </div>
    )

    const mamCredentialLoginForm = mamCredentialLogin && (
        <>
            <div className="login-input">
                <label htmlFor="login-username"><HiOutlineUser /></label>
                <input id="login-username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Username" />
            </div>
            <div className="login-input">
                <label htmlFor="login-password"><FiLock /></label>
                <input id="login-password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password" />
            </div>
            <div className="error-msg login">{error}</div>
            <button type="submit" >
                Log in
            </button>
            <div onClick={toPasswordReset} className="forgot-password-btn">
                Forgot password?
            </div>
        </>
    )

    const loginPage = (
        <form onSubmit={onLogin}>
            <div className="login-welcome">Welcome to {mamName}</div>
            <div className="mam-long-name">Media Asset Management</div>
            {Config.fotbollkontoLoginEnabled && (
                <div className="login-fotbollkonto-container">
                    <button type="button" onClick={onClickFotbollkonto}>
                        Log in via Fotbollkonto
                    </button>
                    <div className="login-or">OR</div>
                </div>
            )}
            <div 
                onClick={handleMamCredentialLogin} 
                className={classNames("login-with-username", {"sef": isSef})}>
                Login with MAM username
            </div>
            {mamCredentialLoginForm}
        </form>
    )

    return (
        <Page centered>
            <div className="login-page">
                <div className="login-container">
                    {loginScreenLogoAndName}
                    {loginPage}
                </div>
                <div className="login-build-version-text">
                    Version: {Config.buildVersion}
                </div>
            </div>
        </Page>
    )
}