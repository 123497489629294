import React, { useState } from 'react';
import Page from '../../components/Page';
import { Helmet } from 'react-helmet-async';
import './Privacy.css';



export default function Privacy() {
    return (
        <Page centered>
            <Helmet>
                <title>Privacy Policy</title>
            </Helmet>
            <div className="privacy-policy-content">
                <h1>Privacy Policy of Forzasys AS</h1>
                <p><strong>Version:</strong> Date: 22/01/2024</p>
                <p>
                    At Forzasys AS, safeguarding your privacy is of utmost importance to us. This policy outlines our practices for handling your Personal Data in connection with the use of Forzify. It covers the collection, usage, disclosure, transfer, and storage of your information.
                    Whenever you engage with Forzify's digital services, your Personal Data will be managed by Forzasys AS. Forzasys AS, located at Ullevaal Stadion, Sognsveien 75D, 0840 Oslo, Norway, acts as the responsible entity for processing your Personal Data in relation to the Services.
                </p>
                <h2>1. Definition and Types of Personal Data Collected</h2>
                <p>Personal Data refers to any data or information that can be used to identify a person either directly or indirectly. When you interact with our Services or Websites, we collect Personal Data associated with you. This includes, but is not limited to, details like your name, username, social media handles, profile pictures from social media, and information related to your behavior or the devices you use to access our Services or Websites. For instance, we may collect your device's MAC address and your mobile device's location data, provided you have activated location services on it. Please note that if you decide against enabling location services, our Services might not function or may offer limited functionality. </p>
                <p>In addition to this, we gather data that is not directly identifiable to you. This includes combined or aggregate information about the usage patterns of various users on our Services. This data helps us improve and provide better information to our users and to understand which sections of our Website and Services are most popular and engaging.</p>

                <h2>2. Usage and Legal Grounds for Processing Your Personal Data</h2>
                <p>At Forzasys, we process your Personal Data in strict adherence to Data Protection Legislation. The basis for processing your data hinges on either your explicit consent or the necessity of compliance with legal obligations. Should you choose to withdraw your consent, which you can do at any time by reaching out to us via the contact details provided in Section 8, please be aware that this decision will preclude you from continuing as a User of the Services. Additionally, if you initially permitted cookie usage on the Websites but later decide against it, you have the option to modify your web browser settings to disallow cookies.</p>
                <p>Your Personal Data are utilized for the following purposes:</p>
                <ul>
                    <li>To deliver the Services offered by Forzify such as uploading, editing and sharing multimedia content.</li>
                    <li>To engage in communication with you through our customer service, such as in instances of orders or complaints.</li>
                    <li>To create, develop, manage, provide, and enhance the Services, the Websites, and their content.</li>
                </ul>
                <p>Additionally, your Personal Data are employed in our internal analyses aimed at improving Forzify, the Services, the Websites, and our communication with our Users. We also indirectly process your data for administrative and testing purposes related to the Websites and our systems within Forzify.</p>

                <h2>3. Sharing and Disclosure of Personal Data</h2>
                <p>Your Personal Data may be accessed by our partners, such as suppliers and IT providers, both within and outside the EU/EEA, to manage and fulfill our agreement with you and ensure the smooth functioning of our IT systems. Depending on the Services you use, we might also share your data with our subsidiaries, associations, or suppliers as per this Privacy Policy. This is to enable them to offer you the requested services or products. Additionally, your Personal Data could be merged with other data from the Services or third parties for service improvement and marketing purposes.</p>
                <p>We have binding agreements with these parties ensuring they process your Personal Data only under our directives and in line with Data Protection legislation, not using it for any purposes other than those specified in this Policy. They may have their own privacy policies for handling your Personal Data, and if so, they are responsible for informing you about them.</p>
                <p>Your Personal Data is always treated confidentially and protected by adequate security measures. When dealing with IT service providers outside the EU/EEA, we guarantee data protection, for instance, through agreements incorporating the European Commission's model clauses for Personal Data protection.</p>
                <p>We may also disclose Personal Data as required by law, or to prevent or investigate potential illegal activities, respond to legal actions, or enforce our rights and claims.</p>

                <h2>4. Interaction with Third-Party Services</h2>
                <p>Our Services and Websites may contain links leading to third-party websites. Please be aware that we are not responsible for the processing of your Personal Data by these external sites. We strongly advise you to review the privacy practices and terms of these third-party sites to understand how they handle your Personal Data.</p>
                <p>Additionally, within our Services, there is the functionality to export information to third-party applications and websites, including but not limited to platforms like Facebook or TikTok. When you utilize these features, it is possible that your Personal Data may be exported as well. We do not hold responsibility for how these third parties process your Personal Data. It's important to be mindful that once your information is shared on these platforms, it might become accessible to other users of these applications or websites.</p>

                <h2>5. Security Measures for Personal Data Protection</h2>
                <p>At Forzasys AS, we prioritize the security of your personal information with utmost seriousness. To safeguard your Personal Data, we have implemented robust security measures including password protection and the use of encryption, among other technical strategies, to prevent unauthorized access and disclosure of the personal information you entrust to us.</p>
                <p>However, it's important to note that certain Services or when you upload/share content, your personal information and the content you choose to upload or share might become exposed to other users. This exposure could lead to your personal information being read, collected, or used by others. We urge you to be mindful of the personal information you decide to disclose in such scenarios. For instance, if you post your name and email address in a public forum, this information becomes publicly accessible. You bear responsibility for the personal information you elect to share or provide in these instances.</p>

                <h2>6. Duration of Personal Data Storage</h2>
                <p>We adhere to a principle of storing your Personal Data only for the period necessary to achieve the objectives outlined in this Policy, or as mandated by legal requirements. To ensure compliance with these guidelines, we have established procedures designed to effectively erase your data once these conditions are fulfilled. This approach ensures that your Personal Data is not held beyond the necessary timeframe for its intended purpose or legal obligations.</p>

                <h2>7. Your Rights Regarding Personal Data: Access, Update, Rectification, and Erasure</h2>
                <p>Under the Data Protection legislation, you are entitled to several rights concerning your Personal Data that we process. These rights include:</p>
                <ol>
                    <li><strong>Access:</strong> You can request access to your Personal Data that we hold at any time.</li>
                    <li><strong>Rectification:</strong> If you find that the Personal Data we have is incorrect, you can ask for it to be updated or corrected.</li>
                    <li><strong>Erasure and Restriction:</strong> You have the right to ask us to stop processing and to erase your Personal Data, especially those processed based on your consent. You can also request that we restrict the processing of your Personal Data.</li>
                    <li><strong>Objection:</strong> You have the right to object to the processing of your Personal Data.</li>
                    <li><strong>Data Portability:</strong> This right allows you to obtain your Personal Data in a portable format.</li>
                </ol>
                <p>To exercise any of these rights, please contact our Data Protection Officer using the contact details provided in Section 8.</p>
                <p>Furthermore, if you believe that Forzify's processing of your Personal Data is not in compliance with Data Protection legislation, you have the right to file a complaint with Forzasys AS, the relevant supervisory authority, at any time.</p>
                
                <h2>8. Questions about privacy and Personal Data processing</h2>
                <p>If you have any questions or concerns about this Privacy Policy or if you would like to make a complaint, please contact us at:</p>
                <p>Address: Ullevaal Stadion Sognsveien 75D 0840 Oslo Norway</p>
                <p>Telephone: +47 970 80 007</p>
                <p>E-mail: contact@forzasys.com</p>

                <h2>9. Use of Cookies on Forzify</h2>
                <p>Forzify employs cookies for enhancing user experience on its Services and Websites. Cookies are small text files stored in your web browser, categorized as either persisten or session cookies. Cookies serve multiple functions, such as gathering visitor statistics, understanding user preferences regarding operating systems and browsers, tracking website usage for improvement purposes, and identifying popular or less frequented pages. They are crucial for certain website features. If you allow cookies and subsequently register as a User, we can link past cookie-collected information to you, enabling personalized content and marketing. To avoid this tracking, you can block cookies.</p>
                <p>You have the option to disable cookies through your web browser settings, which may affect certain functionalities of the Services and Websites. Most browsers are set to accept cookies by default, but you can choose to get prompted for each cookie or to reject all cookies. Instructions for these settings can be found in your browser’s help section. Disabling cookies might impact the performance and functionality of the Services and Websites.</p>

            </div>
        </Page>
    );
}