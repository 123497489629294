import React, {useEffect, useState} from "react"
import { useBackend } from "../../../utility/Backend";
import { useCheckGraphicPackagesPermission, useUpdateSearchParams } from "../../../utility/Utilities";
import { Helmet } from "react-helmet-async";
import Page, {DualPage} from "../../../components/Page";
import "./GraphicPackage.css"
import GraphicPackageForm from "./GraphicPackageForm";
import PackageDetails from "./PackageDetails";
import classNames from "classnames";

function GraphicPackage () {

    const [searchParams, updateSearchParams, resetAllSearchParamsExcept] = useUpdateSearchParams();

    const {data: templatesData} = useBackend("/graphics/template");
    const {data: packagesData, mutate} = useBackend("/graphics/package");

    const graphicTemplates = templatesData?.graphics_templates || []
    const graphicPackages = packagesData?.graphics_packages || []

    const hasTemplates = graphicTemplates.length !== 0
    const packageParams = searchParams.get("package")
    const createPackageParams = packageParams === "create"
    const singlePackageParams = !!packageParams && packageParams !== "create"
    const showGraphicPackagePage = useCheckGraphicPackagesPermission()

    const [packageForDuplicate, setPackageForDuplicate] = useState(null)

    useEffect(() => {
        if (!createPackageParams && packageForDuplicate) setPackageForDuplicate(null)
    }, [createPackageParams])

    if (!showGraphicPackagePage) return null

    const singlePackage = graphicPackages.find((p) => p.id === parseInt(packageParams))

    const createPackage = () => {
        if (packageForDuplicate) setPackageForDuplicate(null)
        updateSearchParams({"package": "create"})
    }

    const duplicatePackage = (graphicPackage) => {
        setPackageForDuplicate(graphicPackage)
        updateSearchParams({"package": "create"})
    }

    const selectViewPackage = (id) => {
        updateSearchParams({"package": id})
    }

    const createPackageBtn = (
        <div className="confirm-cancel-btn-cont right">
            <button onClick={createPackage} className="green-hover-btn">
                Create new package
            </button>
        </div>
    )

    const graphicPackagesList = (
        <div className="graphic-packages-cont">
            {createPackageBtn}
            <br />
            {graphicPackages.map((p) => {
                return (
                    <div 
                        key={p.id} 
                        onClick={() => selectViewPackage(p.id)} 
                        className={classNames("graphic-package-single", {"active": p.id === parseInt(packageParams)})}
                        >
                        <div className="graphic-package-name">{p.name}</div>
                        <div className="graphic-template-name">
                            <span>Template :</span>
                            {p.template.name}
                        </div>
                    </div>
                )
            })}
        </div>
    )

    return (
        <DualPage withLayoutSelection rightPageQueryParam="package">
            <Page title="Graphic Packages">
                <Helmet>
                    <title>Teams</title>
                </Helmet>
                {graphicPackagesList}
            </Page>
            <Page title={createPackageParams ? "create graphic package" : "graphic package"}>
                {(createPackageParams && hasTemplates) && (
                    <GraphicPackageForm
                        templates={graphicTemplates}
                        packageForDuplicate={packageForDuplicate}
                        mutate={mutate}
                        onCancel={() => resetAllSearchParamsExcept([])}/>
                )}
                {singlePackageParams && (
                    <PackageDetails singlePackage={singlePackage} duplicatePackage={duplicatePackage}/>
                )}
            </Page>
        </DualPage>
    )
}

export default GraphicPackage