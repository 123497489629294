import React, {useState, useEffect} from "react"
import Config from "../../../utility/Config";
import { usePeriodFilter } from "../../../utility/UsePeriodFilter";
import "./VideoStatistics.css"
import "../../Statistics/Statistics.css"
import classNames from "classnames"

function VideoStatistics ({playlist, viewStatistic}) {
    
    const {from, to, periodFilterOptions} = usePeriodFilter(true, "This month")
    const [hideGraphs, setHideGraphs] = useState(true)

    useEffect(() => {
        setHideGraphs(true)
    }, [from, to])

    useEffect(() => {
        const clearRefreshStream = setInterval(()=> {
            if (hideGraphs) setHideGraphs(false)
        }, 1200)
        return () => clearInterval(clearRefreshStream);
    }, [hideGraphs])

    const playlistId = playlist?.id
    
    const statisticGraphs = Config.statisticGraphs
    const videoViewGraphUrl = `${statisticGraphs.videoViewGraph.url}?orgId=${statisticGraphs.videoViewGraph.orgId}&var-channel_id=${statisticGraphs.videoViewGraph.channelId}&panelId=${statisticGraphs.videoViewGraph.panelId}&var-playlist_id=${playlistId}&from=${from}&to=${to}`
    const videoDurationGraphUrl = `${statisticGraphs.videoDurationGraph.url}?orgId=${statisticGraphs.videoDurationGraph.orgId}&var-channel_id=${statisticGraphs.videoDurationGraph.channelId}&panelId=${statisticGraphs.videoDurationGraph.panelId}&var-playlist_id=${playlistId}&from=${from}&to=${to}`
    
    const videoGraphs = viewStatistic && (
        <>
            <div className="video-statistic-graph">
                <iframe src={videoViewGraphUrl} title="view count graph" frameborder="0"></iframe>
            </div>
            <div className="video-statistic-graph">
                <iframe src={videoDurationGraphUrl} title="view count graph" frameborder="0"></iframe>
            </div>
        </>
    )

    const loadingBlock = (
        <div className="blank-page-metadata">
            <div className="shape-loading">
                <div className="loading-left-box"></div>
                <div className="loading-middle-box"></div>
                <div className="loading-right-box"></div>
            </div>
        </div>
    )

    return (
        <div className={classNames("video-statistics-cont", {"view": viewStatistic})}>
            <div className="video-statistic-filter">
                {periodFilterOptions}
            </div>
            {videoGraphs}
            {hideGraphs && loadingBlock}
        </div>
    )
}

export default VideoStatistics