import React from "react"
import Config from "../../utility/Config"
import { useTagging } from "../../stores/VideoTagging"
import { useBackend } from "../../utility/Backend"
import { formatTimecode } from "../../utility/Utilities"
import { tagToIcon } from "../../utility/Utilities"
import { tagToIconShl } from "../../utility/Utilities"
import { hockeyTimeFormat } from "../../utility/Utilities"
import classNames from "classnames"
import "./Tagging.css"

function TaggedEventsList () {

    const {taggingProps} = useTagging()

    const gameId = taggingProps.gameId
    const taggingTime = taggingProps.taggingTime

    const { data: eventsData } = useBackend("/game/" + gameId + "/events", {asc: false, count: 120})
    const events = eventsData?.events || []
    const taggedEvents = events.filter((e) => new Date(e.created) > taggingTime)

    if (taggedEvents.length === 0) return null

    const isShl = Config.association === "SHL"

    const renderTaggedEvents = taggedEvents.map((e) => {
        
        const game = e.playlist.game
        const gameTime = Math.ceil(e.game_time / 60);
        const tag = e.tag

        function getTeamLogo () {
            if (!game || !tag || !tag.team) return null;
            const teamId = tag.team.id;
            let team;
            if (teamId === game.home_team.id) team = game.home_team;
            if (teamId === game.visiting_team.id) team = game.visiting_team;
            if (!team) return null;
            return <img src={team.logo_url} alt={team.name} />
        }

        const shlGameTime = isShl && hockeyTimeFormat(tag)
        const eventGameTime = isShl? shlGameTime : gameTime + "'"
        const eventLink = `library/match/${game.id}?editing=event_${e.id}`

        return (
            <a 
                href={eventLink}
                target="_blank"
                rel="noreferrer"
                className={classNames("video-entry tagged-event", {
                "shl": isShl,
                })}>
                <div className="video-entry-thumb">
                    <img src={e.playlist.thumbnail_url} alt="thumbnail" />
                    {e.playlist && (
                        <div className="video-duration">{formatTimecode(e.playlist.duration_ms/1000)}</div>
                    )}
                </div>
                <div className="video-entry-logos">
                    {getTeamLogo()}
                </div>
                <div className="video-entry-top-bottom">
                    <div className="video-entry-top">
                        <div className="game-time">
                            {eventGameTime}
                        </div>
                        <div className="video-entry-icons">
                            {isShl? tagToIconShl(tag.action) :  tagToIcon(tag.action)}
                        </div>
                    </div>
                    <div className="video-entry-bottom">
                        <div className="video-entry-details">
                            {e.playlist.description}
                        </div>
                    </div>
                </div>
            </a>
        )
    })

    return (
        <div className="tagged-events-cont">
            {/* <div className="tagging-section-title"> */}
            <div className="tagged-events-title">
                Tagged Events
            </div>
            {renderTaggedEvents}
        </div>
    )
}

export default TaggedEventsList