import React, {useState} from "react"
import { useBackend } from "../../../utility/Backend"
import Backend from "../../../utility/Backend";
import { useFeedbackMessage } from "../../../stores/FeedbackMessage";
import { useLoginSession } from "../../../stores/loginSession";
import CustomModal from "../../../components/CustomModal";
import {TiGroup} from "react-icons/ti";
import "../../../components/css/Permission.css";
import {FaUser} from "react-icons/fa";
import {FiCheckSquare} from "react-icons/fi";
import {BsSquare} from "react-icons/bs";
import {BiSort} from "react-icons/bi";
import { useCheckIsAdmin } from "../../../utility/UserGroups";

function RenderTaggingAllowedUsergroups ({taggingPermissionUserGroups, removeTaggingPermission}) {

    if (taggingPermissionUserGroups.length === 0) return null

    const list = taggingPermissionUserGroups.map((group) => {
        return (
            <div key={group.id} className="user-permission-single">
                <div className="permission-user-group tagging">
                    <div className="permission-list-user">
                        <div className="permission-list-profile">
                            <FaUser/>
                        </div>
                        {group.name}
                    </div>
                </div>
                <div className="user-permission-type tagging">
                    <FiCheckSquare onClick={() => removeTaggingPermission(group.id)} className="permission-check-icon"/>
                </div>
            </div>
        )
    })
    
    return (
        <>
            {list}
            <br />
        </>
    )
}

function RenderNoPermissionUserGroups ({noPermissionUserGroups, giveTaggingPermission}) {

    if (noPermissionUserGroups.length === 0) return null

    const list = noPermissionUserGroups.map((group) => {
        return (
            <div key={group.id} className="user-permission-single">
                <div className="permission-user-group tagging">
                    <div className="permission-list-user">
                        <div className="permission-list-profile">
                            {group.logo_url? (<img src={group.logo_url} alt="logo"/>) : 
                            (<div><FaUser/></div>)}
                        </div>
                        {group.name}
                    </div>
                </div>
                <div className="user-permission-type tagging">
                    <BsSquare onClick={() => giveTaggingPermission(group.id)} className="permission-uncheck-icon"/>
                </div>
            </div>
        )
    })

    return list
}

function AssetTaggingPermissionModal ({asset, playlist, onClose, isAdmin}) {

    const [searchGroup, setSearchGroup] = useState("")
    const [ascending, setAscending] = useState(true)

    const {token} = useLoginSession()
    const query = {access_token: token}
    const {showFeedback} = useFeedbackMessage()

    const {data: taggingUserGroupsData, mutate} = useBackend(`/asset/${asset.id}/usergroup`, query)
    const {data: userGroupsData, mutate: mutateUserGroupsData} = useBackend("/usergroup", query)
    
    if (!taggingUserGroupsData || !userGroupsData) return null

    if (!isAdmin) return (
        <div>You have no permission to see this page</div>
    )

    const taggingUsergroups = taggingUserGroupsData?.usergroups || []
    const userGroups = userGroupsData?.usergroups || []

    const permittedUsersId = taggingUsergroups.map(g => g?.usergroup?.id)

    // usergroups WITH tagging permission
    let taggingPermissionUserGroups = taggingUsergroups.map((group) => {
        return {
            id: group.usergroup.id,
            name: group.usergroup.usergroup,
        }
    })

    // usergroups WITHOUT tagging permission
    let noPermissionUserGroups = userGroups
        .filter(group => group.name !== ("fs_admin"))
        .filter(group => group.name !== ("sef_admin"))
        .filter(group => !permittedUsersId.includes(group.id))
        .map(group => {
            return {
                id: group.id,
                name: group.name,
                permission: undefined,
                logo_url: group.logo_url,
            }
        })

    const ascendingSort = (a, b) => a.name.localeCompare(b.name)
    const descendingSort = (a, b) => b.name.localeCompare(a.name)

    taggingPermissionUserGroups.sort(ascendingSort)
    noPermissionUserGroups.sort(ascendingSort)

    if (!ascending) {
        taggingPermissionUserGroups.sort(descendingSort)
        noPermissionUserGroups.sort(descendingSort)
    }

    if (searchGroup !== "") {
        taggingPermissionUserGroups = taggingPermissionUserGroups.filter((group) => group.name.toLowerCase().includes(searchGroup.toLowerCase()));
        noPermissionUserGroups = noPermissionUserGroups.filter((group) => group.name.toLowerCase().includes(searchGroup.toLowerCase()));
    }
    
    const removeTaggingPermission = (groupId) => {
        Backend.delete("/asset/" + asset?.id + "/usergroup/" + groupId + "/tag", query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to DELETE", error)
                    showFeedback("warning", "Failed to remove tagging permission, " + error)
                } else {
                    console.log("usergroup tagging permission removed")
                    mutate(taggingUserGroupsData)
                    mutateUserGroupsData(userGroupsData)
                }
            });
    }

    const giveTaggingPermission = (groupId) => {
        Backend.post("/asset/" + asset?.id + "/usergroup/" + groupId + "/tag", query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to POST", error)
                    showFeedback("warning", "Failed to give tagging permission, " + error)
                } else {
                    console.log("usergroup tagging permission granted")
                    mutate(taggingUserGroupsData)
                    mutateUserGroupsData(userGroupsData)
                }
            });
    }

    return (
        <CustomModal isOpen onRequestClose={onClose}>
            <div className="permission-cont">
                <div className="permission-clip-title">
                    <img src={playlist.thumbnail_url} alt="thumbnail"/>
                    {playlist.description}
                </div>
                <div className="available-permission-search">
                    Search
                    <input 
                        type="text" 
                        value={searchGroup}
                        onChange={(e) => setSearchGroup(e.target.value)}/>
                </div>
                <div className="user-permission-cont scrollable">
                    <div className="table-header-cont sticky">
                        <div onClick={() => setAscending(!ascending)} className="permission-user-group table-header-sort tagging">
                            User group <BiSort className="table-header-sort-icon"/>
                        </div>
                        <div className="user-permission-type tagging">Tagging</div>
                    </div>
                    <RenderTaggingAllowedUsergroups 
                        taggingPermissionUserGroups={taggingPermissionUserGroups}
                        removeTaggingPermission={removeTaggingPermission}/>
                    <RenderNoPermissionUserGroups 
                        noPermissionUserGroups={noPermissionUserGroups}
                        giveTaggingPermission={giveTaggingPermission}/>
                </div>
                <button type="button" onClick={onClose} className="permission-close-btn">Close</button>
            </div>
            {/* {lastEditAccessWarning && lastEditAccessModal} */}
        </CustomModal>
    )
}

function AssetInformation ({playlist}) {

    const {token} = useLoginSession()
    const query = {access_token: token}
    const [,isAdmin] = useCheckIsAdmin()
    

    const masterAssetId = playlist.master_videoasset.id

    const {data: asset} = useBackend("/asset/" + masterAssetId, query)
    
    const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false)

    return (
        <div className="data-info-cont">
            <div className="data-info-head-title">Asset Information</div>
            <div>
                <div className="data-info">
                    <div className="data-info-title">Bitrate</div>
                    <div className="data-info-value">
                        
                    </div>
                </div>
                <div className="data-info">
                    <div className="data-info-title">Audio tracks</div>
                    <div className="data-info-value">
                        
                    </div>
                </div>
                <div className="data-info">
                    <div className="data-info-title">Content source</div>
                    <div className="data-info-value">
                        
                    </div>
                </div>
                {isAdmin && (
                    <div className="confirm-cancel-btn-cont between margin-top">
                        <button onClick={() => setIsPermissionModalOpen(true)}>
                            <span><TiGroup className="icon-in-btn"/> Asset permissions</span>
                        </button>
                    </div>
                )}
            </div>
            {isPermissionModalOpen && (
                <AssetTaggingPermissionModal
                    asset={asset}
                    playlist={playlist}
                    onClose={() => setIsPermissionModalOpen(false)}
                    isAdmin={isAdmin}/>
            )}
        </div>
    )
}

export default AssetInformation