import React, {useState} from "react"
import Backend from "../../../utility/Backend"
import { useBackend } from "../../../utility/Backend"
import { useLoginSession } from "../../../stores/loginSession"
import { useFeedbackMessage } from "../../../stores/FeedbackMessage"
import CustomModal from "../../../components/CustomModal"
import {BiSort} from "react-icons/bi";
import {FaUser} from "react-icons/fa";
import {FiCheckSquare} from "react-icons/fi";
import {BsSquare} from "react-icons/bs";
import "../../../components/css/Permission.css";

function RenderTaggingAllowedUsergroups ({taggingPermissionUserGroups, giveTaggingPermission, giveTagGrantingPermission, removeTaggingPermission, removeTagGrantingPermission}) {

    if (!taggingPermissionUserGroups || taggingPermissionUserGroups.length === 0) return null

    const list = taggingPermissionUserGroups.map((group) => {

        const tag = group.permission?.includes("game_tag")
        const grant = group.permission?.includes("game_grant")

        return (
            <div key={group.id} className="user-permission-single">
                <div className="permission-user-group game">
                    <div className="permission-list-user">
                        <div className="permission-list-profile"><FaUser/></div>
                        {group.name}
                    </div>
                </div>
                <div className="user-permission-type game">
                    {tag ? 
                        <FiCheckSquare onClick={() => removeTaggingPermission(group.id)} className="permission-check-icon"/> :
                        <BsSquare onClick={() => giveTaggingPermission(group.id)} className="permission-uncheck-icon"/>
                    }
                </div>
                <div className="user-permission-type game">
                    {grant ? 
                        <FiCheckSquare onClick={() => removeTagGrantingPermission(group.id)} className="permission-check-icon"/> :
                        <BsSquare onClick={() => giveTagGrantingPermission(group.id)} className="permission-uncheck-icon"/>
                    }
                </div>
            </div>
        )
    })
    
    return (
        <>
            {list}
            <br />
        </>
    )
}

function RenderNoPermissionUserGroups ({noPermissionUserGroups, giveTaggingPermission, giveTagGrantingPermission}) {

    if (!noPermissionUserGroups && noPermissionUserGroups.length === 0) return null

    const list = noPermissionUserGroups.map((group) => {
        return (
            <div key={group.id} className="user-permission-single">
                <div className="permission-user-group game">
                    <div className="permission-list-user">
                        <div className="permission-list-profile">
                            {group.logo_url? (<img src={group.logo_url} alt="logo"/>) : 
                            (<div><FaUser/></div>)}
                        </div>
                        {group.name}
                    </div>
                </div>
                <div className="user-permission-type game">
                    <BsSquare onClick={() => giveTaggingPermission(group.id)} className="permission-uncheck-icon"/>
                </div>
                <div className="user-permission-type game">
                    <BsSquare onClick={() => giveTagGrantingPermission(group.id)} className="permission-uncheck-icon"/>
                </div>
            </div>
        )
    })

    return list
}

function MatchTaggingPermission ({game, onClose}) {

    const [searchGroup, setSearchGroup] = useState("")
    const [ascending, setAscending] = useState(true)

    const {token} = useLoginSession()
    const query = {access_token: token}
    const {showFeedback} = useFeedbackMessage()

    const {data: taggingUserGroupsData, mutate} = useBackend(`/game/${game.id}/usergroup`, query)
    const {data: userGroupsData, mutate: mutateUserGroupsData} = useBackend("/usergroup", query)
    
    if (!taggingUserGroupsData || !userGroupsData) return null

    const userGroupWithPermission = taggingUserGroupsData?.usergroups || []
    const userGroups = userGroupsData?.usergroups || []
    
    const allPermittedGroups = userGroupWithPermission.map((g) => {
        return {
            id: g.usergroup.id,
            name: g.usergroup.usergroup,
            permission: [g.permission],
            logo_url: g.usergroup.logo_url,
        }
    })

    const permittedUsersId = allPermittedGroups.map(group => group.id)

    // check and filter the same user group that has two or more permission
    const uniquePermittedId = []
    // final list of user groups with permissions
    let taggingPermissionUserGroups = []

    for (let i=0; i < allPermittedGroups.length; i++){
        if (!uniquePermittedId.includes(allPermittedGroups[i].id)) {
            uniquePermittedId.push(allPermittedGroups[i].id)
            taggingPermissionUserGroups.push({
                id: allPermittedGroups[i].id,
                name: allPermittedGroups[i].name,
                permission: allPermittedGroups[i].permission,
                logo_url: allPermittedGroups[i].logo_url,
            })
        }
        else {
            for (let e=0; e < taggingPermissionUserGroups.length; e++) {
                if (taggingPermissionUserGroups[e].id === allPermittedGroups[i].id) {
                    taggingPermissionUserGroups[e].permission.push(allPermittedGroups[i].permission[0])
                }
            }
        }
    }

    // usergroups WITHOUT tagging permission
    let noPermissionUserGroups = userGroups
        .filter(group => group.name !== ("fs_admin"))
        .filter(group => group.name !== ("sef_admin"))
        .filter(group => !permittedUsersId.includes(group.id))
        .map(group => {
            return {
                id: group.id,
                name: group.name,
                permission: undefined,
                logo_url: group.logo_url,
            }
        })

    const ascendingSort = (a, b) => a.name.localeCompare(b.name)
    const descendingSort = (a, b) => b.name.localeCompare(a.name)

    taggingPermissionUserGroups.sort(ascendingSort)
    noPermissionUserGroups.sort(ascendingSort)

    if (!ascending) {
        taggingPermissionUserGroups.sort(descendingSort)
        noPermissionUserGroups.sort(descendingSort)
    }

    if (searchGroup !== "") {
        taggingPermissionUserGroups = taggingPermissionUserGroups.filter((group) => group.name.toLowerCase().includes(searchGroup.toLowerCase()));
        noPermissionUserGroups = noPermissionUserGroups.filter((group) => group.name.toLowerCase().includes(searchGroup.toLowerCase()));
    }

    // sort user groups that have most permissions on top of the table
    taggingPermissionUserGroups.sort(function (a, b) {
        const groupA = a.permission.length
        const groupB = b.permission.length
        return (groupA > groupB)? -1 : (groupA < groupB)? 1:0
    })

    const removeTaggingPermission = (groupId) => {
        Backend.delete("/game/" + game?.id + "/usergroup/" + groupId + "/tag", query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to DELETE", error)
                    showFeedback("warning", "Failed to remove tagging permission, " + error)
                } else {
                    console.log("usergroup tagging permission removed")
                    mutate()
                    mutateUserGroupsData(userGroupsData)
                }
            });
    }

    const giveTaggingPermission = (groupId) => {
        Backend.post("/game/" + game?.id + "/usergroup/" + groupId + "/tag", query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to POST", error)
                    showFeedback("warning", "Failed to give tagging permission, " + error)
                } else {
                    console.log("usergroup tagging permission granted")
                    mutate()
                    mutateUserGroupsData(userGroupsData)
                }
            });
    }

    const removeTagGrantingPermission = (groupId) => {
        Backend.delete("/game/" + game?.id + "/usergroup/" + groupId + "/grant", query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to DELETE", error)
                    showFeedback("warning", "Failed to remove tag granting permission, " + error)
                } else {
                    console.log("usergroup tag granting permission removed")
                    mutate()
                    mutateUserGroupsData(userGroupsData)
                }
            });
    }

    const giveTagGrantingPermission = (groupId) => {
        Backend.post("/game/" + game?.id + "/usergroup/" + groupId + "/grant", query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to POST", error)
                    showFeedback("warning", "Failed to give tag granting permission, " + error)
                } else {
                    console.log("usergroup tag granting permission granted")
                    mutate()
                    mutateUserGroupsData(userGroupsData)
                }
            });
    }

    const home = game.home_team;
    const away = game.visiting_team;

    const modalGameTitle = (
        <div className="modal-game-title">
            <img src={home.logo_url} alt="team logo" />
            <div>{home.name}</div>
            <div className="modal-game-title-score">{game.home_team_goals} - {game.visiting_team_goals}</div>
            <div>{away.name}</div>
            <img src={away.logo_url} alt="team logo" />
        </div>
    )
    
    return (
        <CustomModal isOpen onRequestClose={onClose}>
            <div className="permission-cont">
                {modalGameTitle}
                <div className="available-permission-search">
                    Search
                    <input 
                        type="text" 
                        value={searchGroup}
                        onChange={(e) => setSearchGroup(e.target.value)}/>
                </div>
                <div className="user-permission-cont scrollable">
                    <div className="table-header-cont sticky">
                        <div onClick={() => setAscending(!ascending)} className="permission-user-group table-header-sort game">
                            User group <BiSort className="table-header-sort-icon"/>
                        </div>
                        <div className="user-permission-type game">Tagging</div>
                        <div className="user-permission-type game">Granting</div>
                    </div>
                    <RenderTaggingAllowedUsergroups 
                        taggingPermissionUserGroups={taggingPermissionUserGroups}
                        giveTaggingPermission={giveTaggingPermission}
                        giveTagGrantingPermission={giveTagGrantingPermission}
                        removeTaggingPermission={removeTaggingPermission}
                        removeTagGrantingPermission={removeTagGrantingPermission}/>
                    <RenderNoPermissionUserGroups 
                        noPermissionUserGroups={noPermissionUserGroups}
                        giveTaggingPermission={giveTaggingPermission}
                        giveTagGrantingPermission={giveTagGrantingPermission}/>
                </div>
                <button type="button" onClick={onClose} className="permission-close-btn">Close</button>
            </div>
        </CustomModal>
    )
}

export default MatchTaggingPermission