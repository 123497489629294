import React, { useState, useEffect, useRef } from "react";
import Config from "../../utility/Config";
import {useBackend} from "../../utility/Backend";
import {useLoginSession} from "../../stores/loginSession";
import { useFeedbackMessage } from "../../stores/FeedbackMessage";
import { useUpdateSearchParams } from "../../utility/Utilities";
import Backend from "../../utility/Backend";
import ConfirmModal from "../../components/ConfirmModal";
import ManageAllowedEvents from "./ManageAllowedEvents";
import classNames from "classnames";
import { useCheckIsAdmin } from "../../utility/UserGroups";
import { capitalizeFirstLetter } from "../../utility/Utilities";
import { tagToIcon } from "../../utility/Utilities";
import "./UserGroupInfo.css"
import {AiOutlineExclamationCircle} from "react-icons/ai";
import {FaCheck, FaTrashAlt} from "react-icons/fa";
import {TiGroup} from "react-icons/ti";
import { FaCircleUser } from "react-icons/fa6";

function UserGroupInfo () {

    const findUserRef = useRef(null)
    const [searchParams,,] = useUpdateSearchParams();
    const {user, token} = useLoginSession()
    const query = {access_token: token}
    const {showFeedback} = useFeedbackMessage()
    const [isFsAdmin,isAdmin] = useCheckIsAdmin()
    const isSef = Config.association === "SEF"
    const userGroupId = searchParams.get("user")

    const [userInput, setUserInput] = useState("")
    const [searchResult, setSearchResult] = useState("")
    const [removeUser, setRemoveUser] = useState(null)
    const [errorMsg, setErrorMsg] = useState("")
    const [managingEvents, setManagingEvents] = useState(false)

    const showErrorMessage = (message="") => {
        setErrorMsg(message);
    }

    useEffect(() => {
        setUserInput("")
        setSearchResult("")
        showErrorMessage()
    }, [userGroupId])

    useEffect(() => {
        if (userInput) showErrorMessage();
        if (userInput === "") setSearchResult("")
    }, [userInput])

    useEffect(() => {
        if (searchResult && searchResult.total === 0) showErrorMessage("No users found. Please enter an already registered email or fotbollkonto id");
    }, [searchResult])

    const {data: userGroupDetails, mutate: mutateUserGroupDetails} = useBackend("/usergroup/" + userGroupId, query)
    const {data: userGroupEventsData, mutate: mutateUserGroupEvents} = useBackend("/usergroup/" + userGroupId + "/event_actions", query)
    
    if (!userGroupDetails) return null

    // if a non admin tries to open admin usergroup by manually typing url
    if (!isFsAdmin && parseInt(userGroupId) === 9) return (
        <div className="page-no-permission">
            You don't have permission to access this page
        </div>
    )

    const userGroupEvents = userGroupEventsData?.actions || []
    userGroupEvents.sort((a,b) => a.localeCompare(b))
    
    userGroupDetails.users.sort(function (a, b) {
        const groupA = a.name.toLowerCase()
        const groupB = b.name.toLowerCase()
        return (groupA < groupB)? -1 : (groupA > groupB)? 1:0
    })

    const clearAddUser = () => {
        setSearchResult("")
        setUserInput("")
        showErrorMessage()
    }

    const userFound = searchResult && searchResult.total !== 0
    const searchedUserId = userFound && searchResult.users[0].id

    const assignToGroup = () => {
        Backend.post("/usergroup/" + userGroupId + "/user/" + searchedUserId, {}, query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to POST", error)
                    showFeedback("warning", "Failed to assign user, " + error)
                } else {
                    mutateUserGroupDetails(userGroupDetails)
                    showFeedback("success", `${searchResult.users[0].name} has been added to ${userGroupDetails.name} successfully`)
                }
            });
        clearAddUser()
    }

    const removeFromGroup = (id) => {
        Backend.delete("/usergroup/" + userGroupId + "/user/" + id, query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to POST", error)
                    showFeedback("warning", "Failed to remove user, " + error)
                } else {
                    mutateUserGroupDetails(userGroupDetails)
                }
            });
    }
    
    let getUserQuery = {email: userInput.trim(), access_token: token}
    if (!userInput.includes("@")) getUserQuery = {fotbollkonto_id: userInput.trim(), access_token: token}

    const getUser = () => {
        if (!userInput) {
            showErrorMessage("Please enter an already registered email or fotbollkonto id")
            return
        }
        Backend.get("/user/search", getUserQuery)
            .then(({data, error}) => {
                if (error) {
                    console.error("Failed to GET", error)
                    showErrorMessage("no users found")
                } else {
                    mutateUserGroupDetails(userGroupDetails)
                    setSearchResult(data)
                }
            });
    }

    const handleEnter = (e) => {
        if (e.key === "Enter") {
            e.preventDefault()
            getUser()
        }
    }

    const onMutateUserGroupEvents = () => {
        mutateUserGroupEvents(userGroupEventsData)
    }
    
    const isUserSelf = removeUser? user.name === removeUser.name : false
    const userToBeRemoved = isUserSelf? "yourself" : removeUser?.name
    
    const confirmRemoveUserModal = (
        <ConfirmModal
            isOpen
            onClose={() => setRemoveUser(null)}
            onConfirm={() => removeFromGroup(removeUser.id)}
            cancelText="Cancel"
            confirmText="Remove">
            <div className="confirm-icon-message">
                <div className="confirm-icon"><AiOutlineExclamationCircle/></div>
                <div className="confirm-title">Are you sure you want to remove {userToBeRemoved} from this group?</div>
            </div>
        </ConfirmModal>
    )

    const userGroupUsersId = userGroupDetails.users.map(user => user.id)
    const userInUserGroup = userFound && userGroupUsersId.includes(searchedUserId)
    const placeholderInfo = isSef ? "Enter registered email or fotbollkonto id here" : "Enter registered email here"

    const addUserSection = (
        <div className="add-user-cont">
            <div className="group-users-title">Add user</div>
            <div className="add-user-search">
                <input 
                    type="text"
                    onKeyDown={handleEnter}
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    placeholder={placeholderInfo}
                    />
                <button ref={findUserRef} type="button" onClick={getUser}>Find</button>
            </div>
            {errorMsg && <div className="warning-msg">{errorMsg}</div>}
            <div className="add-user-result">
                {userFound && (
                    <div className="add-user-result-name">
                        <div className="add-user-profile"><FaCircleUser /></div>
                        <div className="add-user-display-name">
                            {searchResult.users[0].username || searchResult.users[0].fotbollkonto_unique_name}
                        </div>
                        {!userInUserGroup && <FaCheck className="add-user-check"/>}
                        {!userInUserGroup && <button onClick={assignToGroup}>Add</button>}
                    </div>
                )}
                <div className={classNames("user-in-list", {"visible": userInUserGroup})}>This user is already in the user group</div>
            </div>
        </div>
    )

    const userGroupInformation = (
        <div className="data-info-cont">
            <div className="data-info-head-title">
                User group information
            </div>
            <div className="group-users-info">
                <div className="group-users-title">Users</div>
                {userGroupDetails.users.length !==0 ? (
                    <div className="group-user-cont">
                        {userGroupDetails.users.map((user) => {
                            return (
                                <div key={user.id} className="group-user-single">
                                    <div className="group-user-name">
                                        <div className="group-user-icon"><FaCircleUser /></div>
                                        <div className="group-username">{user.name}</div>
                                    </div>
                                    <div onClick={() => setRemoveUser(user)} className="group-user-remove">
                                        <FaTrashAlt/>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <div className="group-no-user">No users this group</div>
                )}
            </div>
            {addUserSection}
        </div>
    )

    const userGroupAllowedEvents = userGroupEventsData && (
        <div className="data-info-cont">
            <div className="data-info-head-title">
                Allowed tagging events
            </div>
            {userGroupEvents.length !== 0 ? (
                <div className="allowed-events-list">
                    {userGroupEvents.map((e) => {
                        return (
                            <div key={e} className="allowed-event-single">
                                <div className={classNames("allowed-event-single-icon", {
                                    "yellow-card": e === "yellow card"
                                    })}>
                                    {tagToIcon(e)}
                                </div>
                                {capitalizeFirstLetter(e)}
                            </div>
                        )
                    })}
                </div>
            ) : (
                <div className="no-allowed-events">No events</div>
            )}
            {isAdmin && (
                <div className="confirm-cancel-btn-cont">
                    <button 
                        onClick={() => setManagingEvents(true)} 
                        className="manage-allowed-events-btn">
                        Manage allowed events
                    </button>
                </div>
            )}
        </div>
    )

    return (
        <div className="user-group-info-cont">
            <div className="user-group-name">
                <div className="user-group-logo">
                    {userGroupDetails.logo_url? (<img src={userGroupDetails.logo_url} alt="logo"/>) : 
                    (<TiGroup className="user-group-logo-icon"/>)}
                </div>
                {userGroupDetails.name}
            </div>
            {userGroupInformation}
            {userGroupAllowedEvents}
            {removeUser && confirmRemoveUserModal}
            {managingEvents && (
                <ManageAllowedEvents 
                    userGroupId={userGroupId} 
                    userGroupEvents={userGroupEvents}
                    onMutate={onMutateUserGroupEvents}
                    onClose={() => setManagingEvents(false)}/>
            )}
        </div>
    )
}

export default UserGroupInfo