import React from "react"
import ShareToMeta from "./ShareToMeta"
import ShareToYoutube from "./ShareToYoutube"

function ShareVideo ({shareVideo=null, onClose, openShareTable}) {

    if (!shareVideo) return null

    const shareToInstagram = shareVideo.media === "instagram"
    const shareToFacebook = shareVideo.media === "facebook"
    const shareToYoutube = shareVideo.media === "youtube"
    // const shareToTiktok = shareVideo.media === "tiktok"
    // and more coming

    if (shareToInstagram || shareToFacebook) return (
        <ShareToMeta
            shareVideo={shareVideo}
            onClose={onClose} 
            openShareTable={openShareTable}/>
    )

    if (shareToYoutube) return (
        <ShareToYoutube
            shareVideo={shareVideo}
            onClose={onClose} 
            openShareTable={openShareTable}/>
    )

    // if (shareToTiktok) return (
    // )

    else return null
}

export default ShareVideo