import React, {useState, useEffect, useMemo, useRef} from "react"
import Config from "../../utility/Config"
import { useBackend } from "../../utility/Backend"
import { useLoginSession } from "../../stores/loginSession"
import {DebounceInput} from "react-debounce-input"
import CustomModal from "../../components/CustomModal"
import classNames from "classnames"
import { ClickOutside, capitalizeFirstLetter } from "../../utility/Utilities"
import { showErrorMessage} from "../../utility/Utilities";
import "./CategoryParams.css"
import {FaTrashAlt} from "react-icons/fa";
import {TiArrowSortedDown} from "react-icons/ti";

// TODO move the components to different file if it gets bigger

function AddCoachSection ({onSubmit}) {

    const [name, setName] = useState("")

    const handleEnter = (e) => {
        if (e.key === "Enter") {
            e.preventDefault()
            onClickSubmit(name);
        }
    }

    const onClickSubmit = (name) => {
        onSubmit(name)
        setName("")
    }

    return (
        <div className="add-coach-cont">
            <input
                type="text"
                onKeyDown={handleEnter}
                onChange={(e) => setName(e.target.value)}
                value={name}
                placeholder="Add full coach's name here"
                />
            <div className="confirm-cancel-btn-cont right">
                <button type="button" disabled={name.length === 0} onClick={() => onClickSubmit(name)}>
                    Add
                </button>
            </div>
        </div>
    )
}

function SearchPlayerSection ({onSelectPlayer, interview=false}) {

    const playerInputRef = useRef(null)

    const [name, setName] = useState("")
    const [results, setResults] = useState([])
    const [isResultsOpen, setIsResultsOpen] = useState(false)

    useEffect(() => {
        if (name?.length < 2) {
            setResults([])
            return
        }

        (async () => {
            const params = new URLSearchParams({name: name})
            const url = Config.getBackend() + "/player/suggest?" + params
            const response = await fetch(url, {method: "GET", mode: "cors"})
            const content = await response.json()
            setResults(content.players)
        })()
    }, [name])

    useEffect(() => {
        if (document.activeElement === playerInputRef.current) setIsResultsOpen(true)
    }, [document.activeElement])

    useEffect(() => {
        playerInputRef.current.focus()
    }, [])

    const onSelect = (player) => {
        if (interview) onSelectPlayer(player.id, player.name)
        else onSelectPlayer(player)

        setIsResultsOpen(false)
        setName("")
    }
    
    return (
        <div className="upload-dropdown-list">
            <div className="search-player-cont">
                <div className="">Search : </div>
                <DebounceInput
                    inputRef={playerInputRef}
                    style={{fontSize: "17px", width: "250px"}}
                    minLength={2}
                    debounceTimeout={300}
                    placeholder="Type minimum 2 characters to search"
                    onChange={event => setName(event.target.value)} />
            </div>
            {showErrorMessage("No players found", (results.length ===0 && name.length >= 2))}
            {(isResultsOpen && results.length !==0) && (
                <div className="search-player-result-list">
                    <ul>
                        {results.map(player => (
                            <li
                                key={player.id}
                                onClick={() => onSelect(player)}
                                className="player-result-single"
                                >
                                {player.name}
                                {player.team && <img src={player.team.logo_url} className="search-player-team-logo" alt="team logo"/>}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
}

function AddPlayerSection ({state, param, onChange, idx}) {

    const searchPlayerRef = useRef(null)

    const paramName = param.name
    const playerName = state && state[paramName].value
    
    const [isSearchPlayerOpen, setIsSearchPlayerOpen] = useState(false)

    ClickOutside(searchPlayerRef, setIsSearchPlayerOpen)

    const onSelectPlayer = (person) => {
        onChange(paramName, person)
        setIsSearchPlayerOpen(false)
    }

    const removePlayer = (e) => {
        e.stopPropagation()
        onChange (paramName, {id: "", name:""})
    }

    const zIndex = 100 - idx

    const addPlayerInput = (
        <div ref={searchPlayerRef} className="upload-dropdown-cont" style={{zIndex: zIndex}}>
            <div onClick={() => setIsSearchPlayerOpen(!isSearchPlayerOpen)} className="upload-dropdown-title">
                {playerName? (
                    <div className="param-player-name">
                        {playerName}
                        <div onClick={(e) => removePlayer(e)} className="remove-player-name">
                            <FaTrashAlt/>
                            Remove
                        </div>
                    </div>
                    ) : (
                        <div className="param-add-player">Add player</div>
                    )
                }
                <TiArrowSortedDown/>
            </div>
            {isSearchPlayerOpen && <SearchPlayerSection onSelectPlayer={onSelectPlayer}/>}
        </div>
    )

    return (
        <div className="search-modal-cont">
            <div className="input-container">
                <label className="input-title">{paramName}</label>
                {addPlayerInput}
            </div>
        </div>
    )
}

export function InterviewParameters ({tags, category, onChange, onDiscard, onClose}) {

    const searchPlayerRef = useRef(null)

    const players = useMemo(() => tags.filter(t => t.player), [tags])
    const coaches = useMemo(() => tags.filter(t => t.coach), [tags])

    const [isSearchPlayerOpen, setIsSearchPlayerOpen] = useState(false)

    ClickOutside(searchPlayerRef, setIsSearchPlayerOpen)

    const removeTag = (tag) => {
        const otherTags = tags.filter(t => t !== tag)
        if (otherTags.length === 0) otherTags.push({"action": "interview"})
        onChange(otherTags)
    }

    const addTag = (tag) => {
        if (tags.length === 1 && !tags[0].player && !tags[0].coach)
            onChange([tag])
        else
            onChange([...tags, tag])
    }

    const addCoach = (name) => {
        if (coaches.some(tag => tag.coach.value === name)) return // Duplicate - no point adding
        addTag({
            action: "interview",
            coach: {type: "coach", value: name}
        })
    }

    const addPlayer = (id, name) => {
        if (players.some(tag => tag.player.id === id)) return // Duplicate - no point adding
        addTag({
            action: "interview",
            player: {type: "player", id: id, value: name}
        })
        setIsSearchPlayerOpen(false)
    }

    const addPlayerInterview = (
        <>
            <div className="interviewee-cont">
                {players.map((tag) => {
                    return (
                        <div key={tag.player.id} className="param-player-name interview">
                            {tag.player.value}
                            <div onClick={() => removeTag(tag)} className="remove-player-name">
                                <FaTrashAlt/>
                                Remove
                            </div>
                        </div>
                    )
                })}
            </div>
            <div ref={searchPlayerRef} className="upload-dropdown-cont">
                <div onClick={() => setIsSearchPlayerOpen(!isSearchPlayerOpen)} className="upload-dropdown-title">
                    Add player
                    <TiArrowSortedDown/>
                </div>
                {isSearchPlayerOpen && <SearchPlayerSection onSelectPlayer={addPlayer} interview/>}
            </div>
        </>
    )

    const addCoachInterview = (
        <>
            <div className="interviewee-cont">
                {coaches.map((tag) => {
                    return (
                        <div key={tag.coach.value} className="param-player-name interview">
                            {tag.coach.value}
                            <div onClick={() => removeTag(tag)} className="remove-player-name">
                                <FaTrashAlt/>
                                Remove
                            </div>
                        </div>
                    )
                })}
            </div>
            <AddCoachSection onSubmit={addCoach}/>
        </>
    )

    return (
        <CustomModal isOpen onRequestClose={onClose} className="small">
            <div className="category-params-modal-cont">
                <div className="category-params-input-cont">
                    <div className="input-container">
                        <label className="input-title">Player</label>
                        {addPlayerInterview}
                    </div>
                    <div className="input-container">
                        <label className="input-title">Coach</label>
                        {addCoachInterview}
                    </div>
                </div>
                <div className="confirm-cancel-btn-cont full">
                    <button type="button" onClick={onClose}>Confirm</button>
                    <button 
                        type="button" 
                        onClick={() => onDiscard(category)}
                        className="red-btn"
                        >
                        <span><FaTrashAlt className="icon-in-btn"/>Remove category</span>
                    </button>
                </div>
            </div>
        </CustomModal>
    )
}

function AddTeamSection ({state, param, onChange, idx=null}) {

    const addTeamRef = useRef(null)

    const paramDetails = state[param.name]

    const {token} = useLoginSession()
    const query = {access_token: token}

    const {data} = useBackend("/team", query)
    const teamsData = data?.teams || []

    const [isTeamListOpen, setIsTeamListOpen] = useState()

    ClickOutside(addTeamRef, setIsTeamListOpen)

    const onClickUpdateTeam = (teamParamData=null) => {
        onChange(param.name, teamParamData)
        setIsTeamListOpen(false)
    }

    const onClickRemoveTeam = (e) => {
        e.stopPropagation()
        onChange(param.name, {id:"", type: "team", value: ""})
    }

    const zIndex = 100 - idx

    return (
        <div className="input-container" style={{zIndex: zIndex}}>
            <label className="input-title">{capitalizeFirstLetter(param.name)}</label>
            <div ref={addTeamRef} className="upload-dropdown-cont">
                <div onClick={() => setIsTeamListOpen(!isTeamListOpen)} className="upload-dropdown-title">
                    {paramDetails.value? (
                        <div className="param-player-name">
                            {paramDetails.value}
                            <div onClick={(e) => onClickRemoveTeam(e)} className="remove-player-name">
                                <FaTrashAlt/>
                                Remove
                            </div>
                        </div>
                    )  : "None"}
                    <TiArrowSortedDown/>
                </div>
                {isTeamListOpen && (
                    <div className="upload-dropdown-list">
                        {teamsData.map((team) => {
                            const teamParamData = {
                                id: team.id,
                                type: param.name,
                                value: team.name
                            }
                            return (
                                <div 
                                    key={team.id} 
                                    onClick={() => onClickUpdateTeam(teamParamData)}
                                    className={classNames("channel-single", {"active": team.name === paramDetails.value})}
                                    >
                                    {team.name}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}

function OtherParamSection ({state, param, onChange, idx=null}) {

    const paramRef = useRef(null)

    const {token} = useLoginSession()
    const query = {access_token: token}
    
    const paramName = param.name
    const paramType = param.type

    const {data} = useBackend("/tag", query)
    const paramOptions = data?.types[paramType]?.options || []

    const [isParamOptionOpen, setIsParamOptionOpen] = useState()

    ClickOutside(paramRef, setIsParamOptionOpen)

    const onClickUpdateTeam = (teamParamData) => {
        onChange(paramName, teamParamData)
        setIsParamOptionOpen(false)
    }

    const zIndex = 100 - idx
    const paramValue = state[paramName].value || "None"

    // TODO filter unwanted SHL param
    const unlistedParams = ["goal_section", "location"]
    if (unlistedParams.includes(paramName)) return null

    return (
        <div className="input-container" style={{zIndex: zIndex}}>
            <label className="input-title">{paramName}</label>
            <div ref={paramRef} className="upload-dropdown-cont">
                <div onClick={() => setIsParamOptionOpen(!isParamOptionOpen)} className="upload-dropdown-title">
                    {paramValue}
                    <TiArrowSortedDown/>
                </div>
                {isParamOptionOpen && (
                    <div className="upload-dropdown-list">
                        {paramOptions.map((option) => {
                            return (
                                <div 
                                    key={option} 
                                    onClick={() => onClickUpdateTeam(option)}
                                    className={classNames("channel-single", {"active": option === paramValue})}
                                    >
                                    {option}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}

export function InterviewName ({tags}) {

    const players = useMemo(() => tags.filter(t => t.player), [tags])
    const coaches = useMemo(() => tags.filter(t => t.coach), [tags])
    const playerNames = useMemo(() => players.map(tag => tag.player), [players])
    const coachNames = useMemo(() => coaches.map(tag => tag.coach), [coaches])
    const allPersons = playerNames.concat(coachNames)

    return (
        <>
            {allPersons.length !==0? (
                <div className="category-param-details">
                    {allPersons.map(person => {
                        return (
                            <div key={person.id} className="category-param-single">
                                <div>{person.value}</div>
                                <div className="category-param-title">{person.type}</div>
                            </div>
                        )
                    })}
                </div>
            ) : (
                <div className="add-player-coach">Add player/coach</div>
            )}
        </>
        
    )
}

export function GetParamsInput ({state, param, onChange, idx}) {

    if (param.type === "team") return (
        <AddTeamSection state={state} param={param} onChange={onChange} idx={idx}/>
    )
    if (param.type === "player") return (
        <AddPlayerSection state={state} param={param} onChange={onChange} idx={idx}/>
    )
    // Default is the various of other params
    return (
        <OtherParamSection state={state} param={param} onChange={onChange} idx={idx}/>
    )
}

export function CategoryParamModal ({
    state,
    category,
    onSubmit,
    onChange,
    onDiscard, 
    onClose, 
    paramsFromTag=null,
}) {

    return (
        <CustomModal isOpen onRequestClose={onClose} className="small">
            <div className="category-params-modal-cont">
                <div className="category-params-input-cont">
                    {paramsFromTag.map((param, idx) => {
                        return (
                            <GetParamsInput 
                                key={param.name} 
                                state={state} 
                                param={param} 
                                onChange={onChange} 
                                idx={idx}/>
                        )
                    })}
                </div>
                <div className="confirm-cancel-btn-cont full">
                    <button type="button" onClick={onSubmit}>Confirm</button>
                    <button 
                        type="button" 
                        onClick={() => onDiscard(category)} 
                        className="red-btn"
                        >
                        <span><FaTrashAlt className="icon-in-btn"/>Remove category</span>
                    </button>
                </div>  
            </div>
        </CustomModal>
    )
}