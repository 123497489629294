import React, {useEffect, useState} from "react"
import Backend from "../../utility/Backend"
import { useBackend } from "../../utility/Backend"
import Config from "../../utility/Config"
import CustomModal from "../../components/CustomModal"
import SmallDropdown from "../../components/SmallDropdown"
import { useLoginSession } from "../../stores/loginSession"
import { showErrorMessage } from "../../utility/Utilities"
import classNames from "classnames"
import { FaGoogle } from "react-icons/fa";
import "./ShareVideo.css"

const categoryToId = {
    "sports": 17,
    "entertainments": 24,
    "educations": 27,
    "trailers": 44,
} 

function ShareToYoutube ({shareVideo, onClose, openShareTable}) {

    const {token} = useLoginSession()
    const video = shareVideo.video

    const [youtubeUser, setYoutubeUser] = useState(null)
    const [loggingOutGoogle, setLoggingOutGoogle] = useState(false)
    const [loggedOut, setLoggedOut] = useState(false)
    
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [category, setCategory] = useState(null)
    const [privacy, setPrivacy] = useState("public")
    const [sharingError, setSharingError] = useState(null)
    const [formError, setFormError] = useState(null)

    const fetchGoogleCredential = !youtubeUser && !loggedOut

    const {data} = useBackend(fetchGoogleCredential ? "/socials/credentials/youtube/" : null, {access_token: token}, {
        dedupingInterval: fetchGoogleCredential ? undefined : 2900,
        focusThrottleInterval: fetchGoogleCredential ? undefined : 2900,
        refreshInterval: fetchGoogleCredential ? undefined : 3000,
    })

    useEffect(() => {
        if (youtubeUser || loggedOut) return
        if (data && data.length !== 0) setYoutubeUser(data[0])
    }, [data, youtubeUser, loggedOut])

    useEffect(() => {
        if (title && description && youtubeUser?.youtube_id) setFormError(false)
    }, [title, description, youtubeUser])

    const privacyOptions = ["private", "unlisted", "public"]
    const categoryOptions = ["sports", "entertainments", "educations", "trailers"]

    const openGoogleLogin = () => {
        const youtubeLoginUrl = Config.api + "demo-ragnar-l1/socials/credentials/youtube/authorize?access_token=" + token
        window.open(youtubeLoginUrl, "_blank", "width=750, height=750")
        if (loggedOut) setLoggedOut(false)
    }

    const resetYoutubeShare = () => {
        setYoutubeUser(null)
        setTitle("")
        setDescription("")
        setCategory(null)
        setPrivacy("public")
        setLoggingOutGoogle(false)
        setSharingError(false)
        setFormError(false)
    }

    const onShareToYoutube = async () => {

        if (!title || !description) {
            setFormError(true)
            return
        }

        if (!youtubeUser?.youtube_id) {
            setFormError(true)
            return
        }

        const shareToYoutubeBody = {
            media_type: "VIDEO",
            video_url: video.download_link,
            title: title,
            description: description,
            privacyStatus: privacy,
        }

        if (category) shareToYoutubeBody.categoryId = categoryToId[category]

        console.log("Youtube", shareToYoutubeBody);

        const {error} = Backend.post("/socials/share/youtube/" + youtubeUser?.youtube_id, JSON.stringify(shareToYoutubeBody))
        if (error) {
            setSharingError(error)
            console.error("Failed to post", error)
        } else {
            onClose()
            openShareTable()
            console.log("Sharing to Youtube")
        } 
    }

    const onLogoutGoogle = () => {
        const {error} = Backend.delete("/socials/credentials/youtube/" + youtubeUser?.youtube_id)
        if (error) {
            setSharingError(error)
            console.error("Failed to logout", error)
        } else {
            setLoggedOut(true)
            resetYoutubeShare()
            console.log("logged out Google");
        } 
    }

    const youtubeUserDetails = (
        <div className="user-logged-in">
            <div className="logged-in-title">Logged in as</div>
            {youtubeUser ? (
                <div className="share-user-profile">
                    <div className="user-profile-and-name">
                        <img src={youtubeUser?.youtube_picture_url} alt="FB img" />
                        <div>{youtubeUser?.youtube_name}</div>
                        <button onClick={() => setLoggingOutGoogle(true)} className="small-btn red-btn">Logout</button>
                    </div>
                    <div className={classNames("confirm-logout", {"show": loggingOutGoogle})}>
                        <div className="create-delete-credentials-title">Confirm logout?</div>
                        <div className="delete-credentials-btn">
                            <button 
                                onClick={onLogoutGoogle}
                                className="red-btn small-btn">
                                Logout
                            </button>
                            <button 
                                onClick={() => setLoggingOutGoogle(false)} 
                                className="small-btn">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="no-credentials-found">
                        Not logged in to any Google account
                    </div>
                    <div className="login-or-create-credentials">
                        <button onClick={openGoogleLogin} className="login-with-google-btn">
                            <FaGoogle/>
                            Login with Google
                        </button>
                    </div>
                </>
            )}
            {formError && showErrorMessage("Youtube id not found", !youtubeUser?.youtube_id)}
            <div className="fb-login-error">
                {sharingError}
            </div>
        </div>
    )

    const shareToYoutubeForm = (
        <div className="share-video-form">
            <div className="input-container">
                <label className="input-title">Title</label>
                <input
                    type="text"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    placeholder=""
                    className="share-video-title-input"
                    />
                {formError && showErrorMessage("Please fill the title", !title)}
            </div>
            <div className="input-container">
                <label className="input-title">Description</label>
                <input
                    type="text"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    placeholder=""
                    className="share-video-title-input"
                    />
                {formError && showErrorMessage("Please fill the description", !description)}
            </div>
            <div className="input-container">
                <label htmlFor="" className="input-title">Category</label>
                <SmallDropdown 
                    value={category}
                    options={categoryOptions}
                    onChange={(v) => setCategory(v)}
                    noneOption/>
            </div>
            <div className="input-container">
                <label htmlFor="" className="input-title">Privacy</label>
                <SmallDropdown 
                    value={privacy}
                    options={privacyOptions}
                    onChange={(v) => setPrivacy(v)}/>
            </div>
        </div>
    )

    return (
        <CustomModal isOpen={!!shareVideo} onRequestClose={onClose} className="share-modal">
            <div className="share-video-modal">
                <div className="share-video-title">Share video to Youtube</div>
                <div className="share-video-and-credentials">
                    <div className="share-video-review">
                        <video src={video.download_link} controls></video>
                    </div>
                    <div className="credentials-and-form">
                        {youtubeUserDetails}
                        {shareToYoutubeForm}
                        <div className="confirm-cancel-btn-cont right">
                            <button onClick={onShareToYoutube} disabled={formError} className="share-to-social-media-btn green-btn">Share to Youtube</button>
                            <button onClick={onClose}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </CustomModal>
    )
}

export default ShareToYoutube