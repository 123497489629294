import React, {useState, useEffect} from "react"
import { useBackend } from "../../utility/Backend";
import Backend from "../../utility/Backend";
import { showErrorMessage } from "../../utility/Utilities";
import SmallDropdown from "../../components/SmallDropdown";
import { RiFileCopyLine } from "react-icons/ri";
import { ImUser } from "react-icons/im";
import "./ShareVideo.css"
import classNames from "classnames";

function InstagramCredential ({ fbLogin, setCredential }) {

    const { data, mutate: mutateCredentials } = useBackend("/socials/credentials/instagram")
    const credentialsData = data || []

    const credential = credentialsData[0]

    const businessId = credential?.business_id
    const credentialValid = credential?.is_valid || true
    const hasQuotas = credential?.quota_left !== 0

    const [credentialError, setCredentialError] = useState(null)
    const [updateErrorMessage, setUpdateErrorMessage] = useState(null)

    const createNewInstagramCredentials = async () => {
        const accessTokenBody = {
            instagram_user_access_token: fbLogin.access_token,
            expires_in: fbLogin.expires_in
        }
        const {error} = Backend.post("/socials/credentials/instagram", JSON.stringify(accessTokenBody), {})
        if (error) {
            setCredentialError(error)
            console.log("Failed to create new credentials,", error)
        } else {
            mutateCredentials(data)
            console.log("New instagram credentials created")
        }
    }

    const updateInstagramCredentials = async () => {
        const accessTokenBody = {
            instagram_user_access_token: fbLogin.access_token, 
            expires_in: fbLogin.expires_in,
        }
        const {error} = await Backend.put("/socials/credentials/instagram/" + businessId, JSON.stringify(accessTokenBody))
        if (error) {
            setCredentialError(error)
            setUpdateErrorMessage(". Please log out of your Facebook account and log in again using the Facebook page connected to your Instagram business account.");
            console.log("Failed to update Instagram credentials,", error)
        } else {
            mutateCredentials(data)
            console.log("Instagram credentials updated");
        }
    }

    const deleteInstagramCredentials = async () => {
        const {error} = await Backend.delete("/socials/credentials/instagram/" + businessId, {})
        if (error) {
            console.log("Failed to delete Instagram credentials,", error)
            setCredentialError(error)
        } else {
            mutateCredentials([])
            console.log("Instagram credentials deleted", businessId)
        }
    }

    // useEffect(async () => {
    //     if (!data) return
    //     if (credential) {
    //         await updateInstagramCredentials()
    //         setCredential(credential)
    //     } else {
    //         createNewInstagramCredentials()
    //     }
    // }, [data, credential])

    // if (credentialError) return (
    //     <div className="credential-error">
    //         {credentialError}
    //     </div>
    // )

    // if (!data || !credential) return (
    //     <div className="loading-credential">
    //         <div className="loading-icon-cont">
    //             <div className="loading-download-icon">
    //             </div>
    //         </div>
    //     </div>
    // )

    return (
        <div className="credentials-details-single">
            {credential && (
                <div className="credentials-details">
                    <div className="credentials-details-img">
                        {credential.profile_picture_url ? (<img src="" alt="img" />) : (
                            <ImUser/>
                        )}
                    </div>
                    <div className="credentials-details-username-id">
                        <div className="credentials-details-username">
                            <div>Username</div>
                            <span>:</span>
                            {credential.instagram_username}
                        </div>
                        <div className="credentials-details-id">
                            <div>Business id</div>
                            <span>:</span>
                            {credential.business_id}
                        </div>
                    </div>
                </div>
            )}
            {!hasQuotas && <div className="credential-zero-quotas">This credential has zero share quotas</div>}
            {!credentialValid && <div className="credentials-expired">This credential is expired. Please select a valid credential.</div>}
            {credentialError}
            {updateErrorMessage}
            <div className="share-testing-btn">
                <button onClick={createNewInstagramCredentials}>Create</button>
                <button onClick={updateInstagramCredentials}>Update</button>
                <button onClick={deleteInstagramCredentials}>Delete</button>
            </div>
        </div>
    )
}

function ShareToInstagram ({fbLogin, onClose, video, openShareTable}) {

    const [credential, setCredential] = useState(null)
    const [caption, setCaption] = useState("")
    const [mediaType, setMediaType] = useState("reels")
    const [shareToFeed, setShareToFeed] = useState(false)
    const [sharingError, setSharingError] = useState(null)
    const [inputError, setInputError] = useState(null)

    const businessId = credential?.business_id
    const credentialValid = credential?.is_valid
    const hasQuotas = credential?.quota_left !== 0

    useEffect(() => {
        if (!inputError) return
        if (inputError) {
            if (caption.length >= 5 && businessId && credentialValid && hasQuotas) {
                setInputError(false)
            }
        }
    }, [inputError, caption, businessId, credentialValid, hasQuotas])

    const onShareToInstagram = async () => {

        if (!businessId) {
            setInputError(true)
            return
        }

        if (!credentialValid) {
            setInputError(true)
            return
        }

        if (!hasQuotas) {
            setInputError(true)
            return
        }

        const shareToInstagramBody = {
            media_type: mediaType.toUpperCase(),
            video_url: video.download_link,
        }

        if (mediaType === "reels") shareToInstagramBody.share_to_feed = shareToFeed
        if (caption) shareToInstagramBody.caption= caption

        console.log(JSON.stringify(shareToInstagramBody));

        const {error} = await Backend.post("/socials/share/instagram/" + businessId, JSON.stringify(shareToInstagramBody))
        if (error) {
            setSharingError(error)
            console.error("Failed to post", error)
        } else {
            onClose()
            openShareTable()
            console.log("Sharing to Instagram")
        } 
    }

    const instagramCredentials = (
        <div className="instagram-credentials">
            <div className="logged-in-title">Instagram credentials</div>
            {fbLogin ? (
                <InstagramCredential fbLogin={fbLogin} setCredential={setCredential}/>
            ) : (
                <div className="login-for-credentials">
                    Login to facebook to get credential
                </div>
            )}
        </div>
    )

    const shareVideoForm = (
        <div className="share-video-form">
            <div className="input-container">
                <label htmlFor="" className="input-title">Media type</label>
                <SmallDropdown 
                    value={mediaType}
                    options={["reels", "stories"]}
                    onChange={(v) => setMediaType(v)}/>
            </div>
            {mediaType === "reels" && (
                <div className="input-container">
                    <label className="input-title">Caption</label>
                    <input
                        type="text"
                        onChange={(e) => setCaption(e.target.value)}
                        value={caption}
                        placeholder="Min 5 characters"
                        className="share-video-title-input"
                        />
                    <div className="share-original-title">
                        <span className="original-video-desc">Original video title: </span>
                        <span className="original-video-desc">{video.description}</span>
                        <span><RiFileCopyLine onClick={() => setCaption(video.description)} className="use-original-title"/></span>
                    </div>
                </div>
            )}
            {mediaType === "reels" && (
                <div className="input-container-toggle">
                    <label className="toggle-title">Share to feed</label>
                    <div onClick={() => setShareToFeed(!shareToFeed)} className={classNames("toggle-switch", {"active": shareToFeed})}>
                        <div className="toggle-button"></div>
                    </div>
                </div>
            )}
            {showErrorMessage("Failed to post. " + sharingError + ". Please try again later.", sharingError)}
        </div>
    ) 

    return (
        <>
            {instagramCredentials}
            {shareVideoForm}
            <div className="confirm-cancel-btn-cont right">
                <button onClick={onShareToInstagram} disabled={inputError} className="share-to-social-media-btn green-btn">Share to Instagram</button>
                <button onClick={onClose}>Cancel</button>
            </div>
        </>
    )
}

export default ShareToInstagram
