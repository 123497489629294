import React, {useEffect, useState, useRef} from "react";
import Backend from "../utility/Backend";
import {useLoginSession} from "../stores/loginSession";
import Config from "../utility/Config";
import { ClickOutside } from "../utility/Utilities";
import { IoMdArrowDropdown } from "react-icons/io"
import { FiDownload } from "react-icons/fi"

function DirectDownload ({
    playlist=undefined,
    filename=undefined,
    duration=undefined,
    videoUrl=undefined,
}) {

    const {token} = useLoginSession()
    const directDownloadRef = useRef(null)

    const [downloadTypesOpen, setDownloadTypesOpen] = useState(false)
    const [isDownloading, setIsDownloading] = useState(null)

    useEffect(() => {
        if (!isDownloading) return
        const downloadTimeout = setTimeout(() => {
            if (isDownloading) setIsDownloading(null);
        }, 10000);
        return () => clearTimeout(downloadTimeout);
    }, [isDownloading]);

    ClickOutside(directDownloadRef, setDownloadTypesOpen)

    const isTooLong = duration > Config.maximumExportSeconds;

    function onDirectDownload (e, type) {
        
        // prevent double click when downloading
        if (isDownloading) return

        if (Config.premiereProPlugin) type = ".ts"

        const filenameAndExtension = filename + type
        // TODO This is a bit hacky, but the videourl reported by the backend isn't
        //      always the correct domain, and therefore might not be the one the login session is for
        const url = videoUrl
            .replace("Manifest.m3u8","50000000/" + filenameAndExtension)
            .replace(/https:\/\/[^.]*api\..*\.com\//, Config.api)

        setIsDownloading(type)
        setDownloadTypesOpen(false)

        console.log("direct download", url);

        if (Config.premiereProPlugin) {
            e.preventDefault();

            console.log("initiating ppro download", "/playlist/" + playlist.id + "/tdt");

            Backend.get("/playlist/" + playlist.id + "/tdt")
                .then(({ data, error }) => {
                    if (error) {
                        console.error("Failed to GET tdt", error);
                    } else {
                        // Access the data here
                        console.log("Received TDT", data);

                        // Set name to the first 40 characters of the description (replace space with underscore)
                        // if available otherwise use the playlist id
                        const name = playlist.description ? playlist.description.substring(0, 25).replace(/\s/g, "_") : playlist.id;

                        // Prepend the name with the current time this is important cause it's also possible to export parts of a video
                        // and we don't want to overwrite the previous export. The date is prefixed as 23122020T0631_
                        const date = new Date();
                        const dateString = date.getFullYear().toString().slice(-2) + "" +
                                            (date.getMonth() + 1).toString().padStart(2, "0") + "" +
                                            date.getDate().toString().padStart(2, "0") + "T" +
                                            date.getHours().toString().padStart(2, "0") + "" +
                                            date.getMinutes().toString().padStart(2, "0") + "_";

                        window.top.postMessage({
                            type: "ppro",
                            mp4url: url,
                            token: data?.token,
                            name: dateString + name,
                            tdtData: data,
                        }, "*");
                    }
                });
            return;
        }
    
        // Note: I do not like exposing the access token in the DOM
        // + it's easier to control a div than an anchor
        const a = document.createElement("a");
        a.href = url + "?access_token=" + token;
        a.download = filenameAndExtension;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    return (
        <div ref={directDownloadRef} className="direct-download-cont">
            {isDownloading ? (
                <button disabled>
                    Downloading {isDownloading}
                </button>
            ) : (
                <button 
                    onClick={() => setDownloadTypesOpen(!downloadTypesOpen)} 
                    disabled={isTooLong}
                    className="direct-download-option"
                    >
                    <FiDownload className="icon-in-btn"/>
                    Direct download
                    <IoMdArrowDropdown/>
                </button>
            )}
            {downloadTypesOpen && (
                <div className="download-types-cont">
                    <button onClick={(e) => onDirectDownload(e, ".mp4")} className="download-type-single">
                        fMP4
                    </button>
                    <button onClick={(e) => onDirectDownload(e, ".ts")} className="download-type-single">
                        TS - PPro compatible
                    </button>
                </div>
            )}
        </div>
    )
}

export default DirectDownload