import React, {useState, useRef} from "react"
import UploadDragAndDrop from "./UploadDragAndDrop";
import { useEffect } from "react";
import { getDisplayDuration } from "../../utility/Utilities";

function UploadVideoFileSection ({
    chosenFile, 
    url, 
    setUrl, 
    bitrate, 
    setBitrate,
    resolution,
    setResolution,
    handleSelectFile, 
    uploadThumbnail
}) {

    const videoRef = useRef(null);
    
    const [duration, setDuration] = useState(null)

    useEffect(() => {
        if (!chosenFile) {
            setBitrate(0)
            setResolution(null)
            setDuration(null)
        }
    }, [chosenFile])

    const onVideoMetadataLoaded = () => {
    
        const duration = videoRef.current.duration;
        if (duration <= 0) {
            console.log("video has no duration");
            return;
        }
        
        const videoBitRate = Math.round((chosenFile.size * 8) / duration)
        const videoWidth = videoRef.current.videoWidth
        const videoHeight = videoRef.current.videoHeight
        const videoResolution = videoWidth + "x" + videoHeight

        setBitrate(videoBitRate)
        setResolution(videoResolution)
        setDuration(duration * 1000)
    }

    const bitrateMBit = (bitrate / 1024 / 1024).toFixed(2);
    const fileSize = chosenFile && parseFloat((chosenFile.size / 1000000).toFixed(1))

    const fileMetadata = chosenFile && (
        <div className="upload-file-metadata-cont">
            <div className="upload-file-info">
                <div className="upload-info-title">Filename</div>
                <div className="upload-info-value">{chosenFile.name}</div>
            </div>
            <div className="upload-file-info">
                <div className="upload-info-title">File size</div>
                <div className="upload-info-value">{fileSize? `${fileSize} MB` : "-"}</div>
            </div>
            <div className="upload-file-info">
                <div className="upload-info-title">Bitrate</div>
                <div className="upload-info-value">{bitrateMBit>0? `${bitrateMBit} Mbps` : "-"}</div>
            </div>
            <div className="upload-file-info">
                <div className="upload-info-title">Resolution</div>
                <div className="upload-info-value">{resolution !== null? resolution : "-"}</div>
            </div>
            <div className="upload-file-info">
                <div className="upload-info-title">Duration</div>
                <div className="upload-info-value">{duration>0? getDisplayDuration(duration) : "-"}</div>
            </div>
        </div>
    )

    const uploadVideoSection = (
        <div className="upload-video-cont">
            <div className="upload-video-player">
                <video
                    ref={videoRef}
                    id="video"
                    src={url}
                    onLoadedMetadata={onVideoMetadataLoaded}
                    controls
                    />
            </div>
            <div className="upload-dnd-and-meta">
                <div className="upload-page-dnd-cont">
                    <UploadDragAndDrop
                        chosenFile={chosenFile}
                        onFileChange={handleSelectFile}
                        onUrlChange={setUrl}
                        disableInput={uploadThumbnail}
                        smallSize
                    />
                </div>
                {fileMetadata}
            </div>
        </div>
    )

    return (
        <>{uploadVideoSection}</>
    )
}

export default UploadVideoFileSection