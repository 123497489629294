import React, {useState} from "react"

import ExportProgress from "../../components/ExportProgress";
import { capitalizeFirstLetter, getDateFormat, getDisplayDuration, getSizeFormat } from "../../utility/Utilities";
import { useCheckIsAdmin } from "../../utility/UserGroups";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import "./Exports.css"
import {BiSort} from "react-icons/bi";
import {FaTrashAlt} from "react-icons/fa";
import { ImDownload3 } from "react-icons/im";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import {PiShareFatFill} from "react-icons/pi";
import {FaFacebook, FaInstagram, FaTiktok} from "react-icons/fa";

function ExportsTable ({ exports, onDownload, downloadIndex, setShareVideo, setExportToBeRemoved}) {

    const [isFsAdmin,] = useCheckIsAdmin()
    const mobileDevice = useMediaQuery({maxWidth: 768})

    const [viewExportExtra, setViewExportExtra] = useState(null)
    const [sortBy, setSortBy] = useState("submitted ascending")

    if (exports.length <= 0) return (
        <div className="no-exports-table">No exports to show</div>
    )

    const sortSubmitted = () => {
        if (sortBy !== "submitted ascending") setSortBy("submitted ascending")
        else setSortBy("submitted descending")
    }

    const sortExpires = () => {
        if (sortBy !== "expires ascending") setSortBy("expires ascending")
        else setSortBy("expires descending")
    }

    const sortSize = () => {
        if (sortBy !== "size ascending") setSortBy("size ascending")
        else setSortBy("size descending")
    }

    const sortDuration = () => {
        if (sortBy !== "duration ascending") setSortBy("duration ascending")
        else setSortBy("duration descending")
    }

    switch (sortBy) {
        case "submitted ascending":
            exports.sort((a,b) =>  new Date(b.submitted) - new Date(a.submitted))
            break;
        case "submitted descending":
            exports.sort((a,b) => new Date(a.submitted) - new Date(b.submitted)) 
            break;
        case "expires ascending":
            exports.sort((a,b) =>  new Date(b.expires) - new Date(a.expires))
            break;
        case "expires descending":
            exports.sort((a,b) => new Date(a.expires) - new Date(b.expires)) 
            break;
        case "size ascending":
            exports.sort((a,b) =>  new Date(b.size_bytes) - new Date(a.size_bytes))
            break;
        case "size descending":
            exports.sort((a,b) => new Date(a.size_bytes) - new Date(b.size_bytes)) 
            break;
        case "duration ascending":
            exports.sort((a,b) =>  new Date(b.duration_s) - new Date(a.duration_s))
            break;
        case "duration descending":
            exports.sort((a,b) => new Date(a.duration_s) - new Date(b.duration_s)) 
            break;
        default:
            break;
    }

    const handleCloseExportExtra = (e) => {
        e.stopPropagation()
        setViewExportExtra(null)
    }

    if (mobileDevice) return (
        <div className="exports-mobile-cont">
            {exports.map((e, idx) => {
                const isDownloadActive = new Date(e.expires) > new Date()
                return (
                    <div key={e.id} onClick={() => setViewExportExtra(e.id)} className={classNames("export-single-mobile", {"expired": !isDownloadActive})}>
                        <div className="export-mobile-flex-between center">
                            <div className="export-mobile-info">
                                <div className="export-mobile-info-title">
                                    Source
                                </div>
                                <div className="export-mobile-info-desc">
                                    {e.description}
                                </div>
                            </div>
                            <IoMdArrowDropright className="export-mobile-more"/>
                        </div>
                        <div className="export-mobile-flex-between">
                            <div className="export-mobile-flex">
                                <div className="export-mobile-info size">
                                    <div className="export-mobile-info-title">
                                        Size
                                    </div>
                                    <div className="export-mobile-info-size">
                                        {e.size_bytes? getSizeFormat(e.size_bytes) : "-"}
                                    </div>
                                </div>
                                <div className="export-mobile-info">
                                    <div className="export-mobile-info-title">
                                        Duration
                                    </div>
                                    <div className="export-mobile-info-duration">
                                        {e.duration_s > 0? getDisplayDuration(e.duration_s * 1000) : "-"}
                                    </div>
                                </div>
                            </div>
                            {isDownloadActive? (
                                <div className="export-mobile-download">
                                    {downloadIndex.includes(idx) ? (
                                        <div className="export-mobile-download-loading"><div></div> </div>
                                    ) : (
                                        <a href={e.download_link} onClick={(a) => onDownload(a, e.download_link, e.description, idx)}>
                                            <ImDownload3/>
                                        </a>
                                    )}
                                </div>
                                ) : (<div className="download-expired">Expired</div>)}
                        </div>
                        <div onClick={(e) => handleCloseExportExtra(e)} className={classNames("export-mobile-extra", {"show": viewExportExtra === e.id})}>
                            <div className="export-mobile-flex-between center">
                                <div className="export-mobile-flex">
                                    <div className="export-mobile-info">
                                        <div className="export-mobile-info-title">
                                            Preset
                                        </div>
                                        <div className="export-mobile-info-text">
                                            {e.output_preset? capitalizeFirstLetter(e.output_preset.replace("_", " ")) : "-"}
                                        </div>
                                    </div>
                                    <div className="export-mobile-info">
                                        <div className="export-mobile-info-title">
                                            Creator
                                        </div>
                                        <div className="export-mobile-info-text creator">
                                            {e.creator.name}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <IoMdArrowDropleft className="export-mobile-more"/>
                                </div>
                            </div>
                            <div className="export-mobile-flex">
                                <div className="export-mobile-info">
                                    <div className="export-mobile-info-title">
                                        Submitted
                                    </div>
                                    <div className="export-mobile-info-text">
                                        {getDateFormat(e.submitted, true, true)}
                                    </div>
                                </div>
                                <div className="export-mobile-info">
                                    <div className="export-mobile-info-title">
                                        Expired
                                    </div>
                                    <div className="export-mobile-info-text">
                                        {getDateFormat(e.expires, true, true)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )

    return (
        <div className="exports-table scrollable">
            <div className="table-header-cont sticky">
                <div onClick={sortSubmitted} className="export-submitted table-header-sort">
                    Submitted
                    <BiSort className="table-header-sort-icon"/>
                </div>
                <div className="export-source">Source</div>
                <div className="export-preset">Preset</div>
                <div className="export-creator">Creator</div>
                <div onClick={sortExpires} className="export-expires table-header-sort">
                    Expires
                    <BiSort className="table-header-sort-icon"/>
                </div>
                <div onClick={sortSize} className="export-size table-header-sort">
                    Size
                    <BiSort className="table-header-sort-icon"/>
                </div>
                <div onClick={sortDuration} className="export-duration table-header-sort">
                    Duration
                    <BiSort className="table-header-sort-icon"/>
                </div> 
                <div className="export-share">Share</div>
                <div className="export-download">Download</div>
                {isFsAdmin && <div className="export-delete">Del</div>}
            </div>
            {exports.map((e, idx) => {
                const isDownloadActive = new Date(e.expires) > new Date()
                return (
                    <div key={e.id} className={classNames("export-clip-single", {"expired": !isDownloadActive})}>
                        <div className="export-submitted">{getDateFormat(e.submitted, true, true)}</div>
                        <div className="export-source data">{e.description}</div>
                        <div className="export-preset">{e.output_preset? capitalizeFirstLetter(e.output_preset.replace("_", " ")) : "-"}</div>
                        <div className="export-creator">{e.creator.name}</div>
                        {e.download_link? (
                            <>
                                <div className="export-expires">{getDateFormat(e.expires, true, true)}</div>
                                <div className="export-size data">{e.size_bytes? getSizeFormat(e.size_bytes) : "-"}</div>
                                <div className="export-duration data">{e.duration_s > 0? getDisplayDuration(e.duration_s * 1000) : "-"}</div> 
                                <div className={classNames("export-share", {"enabled": isDownloadActive})}>
                                    <div className="share-video">
                                        <div className="share-video-btn">
                                            <PiShareFatFill/>
                                            Share
                                        </div>
                                        <div className="share-social-media-cont">
                                            <FaFacebook onClick={() => setShareVideo({media: "facebook", video: e})}/>
                                            <FaInstagram onClick={() => setShareVideo({media: "instagram", video: e})}/>
                                            {/* <FaTiktok onClick={() => setSharingVideo({media: "tiktok", video: e})}/> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="export-download">
                                    {isDownloadActive? (
                                        <a href={e.download_link} onClick={(a) => onDownload(a, e.download_link, e.description, idx)}>
                                            <button disabled={downloadIndex.includes(idx)} className="smaller-font-btn">
                                                Download
                                            </button>
                                        </a>
                                    ) : (<div className="download-expired">Expired</div>)}
                                </div>
                            </>
                            ) : (
                                <div className="export-processing">
                                    <ExportProgress percentage={e.progress} status={e.status}/>
                                </div>
                            )}
                        {isFsAdmin && (<div className="export-delete icon"><FaTrashAlt onClick={() => setExportToBeRemoved({id: e.id, description: e.description})} className="remove-export-btn"/></div>)}
                    </div>
                )
            })}
        </div>
    )
}

export default ExportsTable