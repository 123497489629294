import React, {useState, useEffect, useReducer} from "react"
import {CategoryParamModal, InterviewParameters, InterviewName} from "./CategoryParams";
import Config from "../../utility/Config";
import "./CategoryParams.css"
import {FiEdit3} from "react-icons/fi";

function getNewTagParams (action, paramsList=null) {

    if (!paramsList) return null

    const newTagParams = {action: action}

    const getParamsDetailsFromTagParam = (param) => {
        if (param.type === "player") {
            return newTagParams[param.name] = {
                id: "",
                type: param.type,
                value: "",
            }
        }
        // Default is other various params
        else {
            return newTagParams[param.name] = {
                type: param.type,
                value: "",
            }
        }
    }

    for (let i=0; i < paramsList.length; i++) {
        getParamsDetailsFromTagParam(paramsList[i])
    }

    return newTagParams
}

function reducer (state, action) {

    const teamParams = ["team", "home team", "visiting team"]
    const isTeamParam = teamParams.includes(action.type)
    const isPlayerParam = state[action.type]?.type === "player"

    if (action.type === "reset") return action.payload

    if (action.type === "newTagParams") return action.payload

    if (isTeamParam) {
        if (state.hasOwnProperty(action.type))
            return {...state, [action.type]: {
                id: action.payload.id,
                type: state[action.type].type,
                value: action.payload.value,
            }}
        return state
    }
            
    if (action.type === "interview") {
        if (state.hasOwnProperty(action.type))
            return action.payload
        return state
    }

    if (isPlayerParam) {
        if (state.hasOwnProperty(action.type))
            return {...state, [action.type]: {
                id: action.payload.id,
                type: state[action.type].type,
                value: action.payload.name,
            }}
        return state
    }
    
    // Default is other various params
    else {
        if (state.hasOwnProperty(action.type)) 
            return {...state, [action.type]: {
                type: state[action.type].type,
                value: action.payload,
            }}
        return state
    }
}

export default function SelectedCategory ({
    category,
    tags, 
    tagWithParams,
    paramsFromTag, 
    changeCategory, 
    removeCategory
}) {
    const tagsObj = tags[0]

    // state of the tag params for editing
    const tagParamsState = tagsObj
    const [state, dispatch] = useReducer(reducer, tagParamsState)
    const [isEditCategory, setIsEditCategory] = useState(false)

    const selectedTagNoParams = Object.keys(tagsObj).length <= 1
    const mustHaveParamsButNoParams = tagWithParams && selectedTagNoParams && category !== "interview"
    
    // to get new fresh tag params to be filled / edit
    const newTagParams = getNewTagParams(category, paramsFromTag)

    // if the tag is supposed to have params, this is to replace the tag to be the one that has params with fresh empty values
    useEffect(() => {
        if (mustHaveParamsButNoParams) {
            changeCategory(category, [newTagParams])
            dispatch({type: "newTagParams", payload: newTagParams})
        }
    }, [mustHaveParamsButNoParams])

    const onChange = (type, payload=null) => {
        dispatch({type, payload})
    }

    const onCancel = () => {
        dispatch({type: "reset", payload: tagsObj})
    }

    const onSubmit = () => {
        changeCategory(category, [state])
        setIsEditCategory(false)
    }

    const onDiscard = (category) => {
        removeCategory(category)
        setIsEditCategory(false)
    }

    // render the data of player related params details (scorer, assist, keeper,...)
    const renderPlayerParamsData = () => {

        if (!tagWithParams) return null 

        if (category === "interview") return <InterviewName tags={tags}/>

        let paramsNames = paramsFromTag.map(param => param.name)

        // TODO filter unwanted SHL param
        const unlistedParams = ["goal_section", "location"]
        if (Config.association === "SHL") paramsNames = paramsNames.filter(param => !unlistedParams.includes(param))

        return (
            <div className="category-param-details">
                {paramsNames.map((param) => {
                    const name = tagsObj[param]?.value || "-"
                    return (
                        <div key={param} className="category-param-single">
                            <div>{name}</div>
                            <div className="category-param-title">{param}</div>
                        </div>
                    )
                })}
            </div>
        )
    }

    let categoryDisplayName = Config.categories[category] || Config.rollCategories[category] || category
    // TODO specific SHL shoot out penalty shot
    if (categoryDisplayName === "shootoutpenaltyshot") categoryDisplayName = "shoot out penalty shot"

    return (
        <>
            <div onClick={() => setIsEditCategory(true)} className="selected-category-single">
                <div className="selected-category-title">{categoryDisplayName}</div>
                {renderPlayerParamsData()}
                <FiEdit3 className="add-category-icon"/>
            </div>

            {(isEditCategory && category !== "interview") && (
                <CategoryParamModal
                    state={state}
                    category={category}
                    onSubmit={onSubmit}
                    onDiscard={onDiscard}
                    onChange={onChange}
                    onCancel={onCancel}
                    onClose={() => setIsEditCategory(false)}
                    paramsFromTag={paramsFromTag}/>
            )}
            {(isEditCategory && category === "interview") && (
                <InterviewParameters
                    tags={tags} 
                    category={category}
                    onChange={(updates) => changeCategory(category, updates)}
                    onDiscard={onDiscard}
                    onClose={() => setIsEditCategory(false)}/>
            )}
        </> 
    )
}