import React, {createContext, useContext} from "react"
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../utility/Utilities";

const TaggingContext = createContext(null);

export function TaggingProvider ({children}) {

    const navigate = useNavigate()
    const [taggingProps, setTaggingProps, cancelTaggingProps] = useLocalStorage("tagging", null)
    
    const tagVideo = (type, gameId) => {
        setTaggingProps({
            type: type, 
            gameId: gameId,
            taggingTime: new Date(),
        })
        navigate("/tagging?game=" + gameId)
    }

    const context = {
        taggingProps,
        cancelTaggingProps,
        tagVideo,
    }

    return (
        <TaggingContext.Provider value={context}>
            {children}
        </TaggingContext.Provider>
    )
}

export function useTagging () {
    const context = useContext(TaggingContext);
    if (context === undefined)
        throw new Error("useTagging used outside of its provider");
    return context;
}