import React from "react"
import Config from "../../../utility/Config";
import { getDateFormat } from "../../../utility/Utilities"
import "./PaymentMetadata.css";

function PaymentMetadata ({playlist}) {

    const isSef = Config.association === "SEF"
    const payment = isSef && playlist.payment
    const pricePlan = isSef && payment?.price_plans[0]

    if (!pricePlan) return null

    const startTime = new Date(playlist.recording_timestamp).getTime()
    const paymentRequiredUntil = new Date(payment?.payment_required_until).getTime()
    const paymentPeriod = Math.round((paymentRequiredUntil - startTime) / 86400000)
    const teamLogo = pricePlan?.merchant.team?.logo_url

    return (
        <div className="data-info">
            <div className="data-info-title">Payment</div>
            <div className="payment-plan-cont">
                <div className="price-merchant">
                    {teamLogo && <img src={teamLogo} alt="logo" className="merchant-club-logo"/>}
                    <div>{pricePlan?.merchant.name}</div>
                </div>
                <div className="payment-price-name">{pricePlan?.name}</div>
                {(pricePlan && !payment.required) && (
                    <div className="payment-period-ended">Payment period is ended</div>
                )}
                <div className="price-period-cont">
                    <div className="price-period-info">
                        <div className="price-period-title">Required for :</div>
                        <div className="price-period-days">{paymentPeriod} days</div>
                    </div>
                    <div className="price-period-info">
                        <div className="price-period-title">Required until :</div>
                        <div className="price-period-until">
                            {getDateFormat(new Date(payment.payment_required_until), true, true)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentMetadata