import React, {useState} from "react";
import "./css/ThumbnailSelector.css";
import { toThumbnailUrl } from "../utility/Utilities";
import CustomModal from "../components/CustomModal";
import UploadImage from "./UploadImage";

export default function ThumbnailSelector ({
    selected,
    onChange,
    playlistClips,
}) {
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUploadImageOpen, setIsUploadImageOpen] = useState(false);

    let clips = playlistClips

    // full playlist with events
    if (playlistClips.events) clips = playlistClips.events.map(e => ({
        from: e.from_timestamp,
        to: e.to_timestamp,
        assetName: e.video_asset_name,
    }))

    const openModal = () => {
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const toggleUploadImageModal = () => {
        setIsUploadImageOpen(!isUploadImageOpen);
    }

    const autoGeneratedThumbnail = () => {
        onChange()
    }

    const checkDefault = /(\d+\.jpg)$/.exec(selected)
    const isAutoGeneratedThumbnail = parseInt(checkDefault && checkDefault[0]) < 5

    const defaultThumbnail = toThumbnailUrl(clips[0].assetName, clips[0].from);

    const RenderModal = () => {

        const maxSetSize = 60;
        const duration = clips.map(c => (c.to - c.from)).reduce((val, acc) => (val + acc), 0);
        const stepSize = Math.max(1000, duration / maxSetSize);

        let options = [];

        const getThumbnails = (clips) => {
            clips.forEach(clip => {
                const end = clip.to - stepSize;
                for (let i=clip.from; i<end; i+=stepSize) {
                    options.push(toThumbnailUrl(clip.assetName, i));
                }
            });
        }

        if (clips.length > 1){
            for (let i = 0; i < clips.length; i++) {
                getThumbnails([clips[i]]);
            }
        } else {
            getThumbnails(clips)
        }

        // In cases where there's loads of thumbs to choose from, it's possible that our existing
        // selection isn't included in our random selection. So we specifically include it.
        if (selected && !options.includes(selected)) {
            options.push(selected)
            options.sort();
        }

        const chooseThumbnail = (image) => {
            onChange(image);
            closeModal();
        }

        return (
            <CustomModal isOpen onRequestClose={closeModal}>
                <div className="thumbnail-modal-cont">
                    <div className="thumbnail-img-cont">
                        {options.map((url, idx) => (
                            <img
                                key={`${url} ${idx}`}
                                src={url}
                                alt="thumbnail"
                                onClick={() => chooseThumbnail(url)}
                                className={`thumbnail-img ${url === selected ? "selected" : ""}`}
                            />
                        ))}
                    </div>
                    <div className="cancel-select-thumbnail">
                        <button type="button" onClick={closeModal}>Cancel</button>
                    </div>
                </div>
            </CustomModal>
        );
    }

    const selectThumbnailOpt = (
        <div className="select-thumbnail-btn">
            <button onClick={openModal}>Select from list</button>
            <button onClick={toggleUploadImageModal}>Upload image</button>
            <button disabled={isAutoGeneratedThumbnail} onClick={autoGeneratedThumbnail}>Auto-generated thumbnail</button>
        </div>
    )

    return (
        <div className="edit-thumbnail-cont">
            {selected? (
                <>
                    <img src={selected} alt="thumbnail" onClick={openModal} className="metadata-thumbnail-img"/>
                    {selectThumbnailOpt}
                </>
            ) : (
                <div className="playlist-thumbnail-cont">
                    <img src={defaultThumbnail} alt="thumbnail" onClick={openModal} className="metadata-thumbnail-img"/>
                    {selectThumbnailOpt}
                </div>
            )}
            {isModalOpen && RenderModal()}
            {isUploadImageOpen && (
                <UploadImage 
                    onChange={onChange}
                    onClose={toggleUploadImageModal}/>
            )}
        </div>
    )
}