import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Config from "./Config";

const track = window.track

function initializeSentry () {
    if (!Config.sentryDSN) return

    Sentry.init({
        dsn: Config.sentryDSN,
        integrations: [new Integrations.BrowserTracing()],
        // environment: , FIXME: May need in the future for staging envs

        // Set tracesSampleRate to 1.0 to capture 100% for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    })
}

export function initializeAnalytics () {
    initializeSentry()
    track?.load("https://events.forzasys.com")
}

export function forzifyAnalytics (...args) {
    console.log("analytics", ...args)
    // if (!Config.isDevEnvironment) track?.track(...args)
    track?.track(...args)
}