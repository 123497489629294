import React, { useState } from 'react';
import Page from '../../components/Page';
import { Helmet } from 'react-helmet-async';
import './Terms.css';



export default function Terms() {

    return (
        <Page centered>
            <Helmet>
                <title>Terms & Conditions</title>
            </Helmet>
            <div className="terms-service-content">
                <h1>GENERAL TERMS & CONDITIONS FOR USING FORZIFY</h1>
                <p className="text-center"><strong>Version:</strong> Date: 22/01/2024</p>
                <p>
                    Welcome to Forzify, a product of Forzasys AS. These General Terms & Conditions (“Terms & Conditions”) are designed to govern your use of and connection to Forzify Digital Services. We encourage you to read these Terms & Conditions thoroughly to understand your rights and responsibilities.
                </p>

                <h2>1. General Information about Forzasys AS and Forzify</h2>
                <p>
                    Forzasys AS, with its registered office at Ullevaal Stadion, Sognsveien 75D, 0840 Oslo, Norway. Our premier product, Forzify, is a versatile live and on-demand video management system. Designed to cater to a wide range of digital needs, Forzify provides services such as OTT streaming, content search and sharing, recommendations, event creation, and video playlists.
                    Forzify extends its digital services to various leagues and clubs, enhancing experiences both at venues and remotely. Our services are not limited to specific events or locations, ensuring year-round availability. As a user of Forzify, your interaction with our platform may vary based on your role be it a club administrator, league administrator, or other. Each role offers unique services tailored to enhance your video management experience.
                </p>

                <h2>2. Accepting the Terms & Conditions</h2>
                <p><strong>Acceptance of Terms:</strong> By registering as a User on any of the Forzasys platforms, you acknowledge that you have read, understood, and agree to be bound by these Terms & Conditions. If you do not agree to these Terms & Conditions, you are not authorized to use the Services.</p>
                
                <p>
                    <strong>Eligibility to Use the Services:</strong> You are eligible to use the Services only if you:
                    <ul>
                        <li>Have reached the age required to form a legally binding contract and have obtained consent from your parent or guardian, if necessary; and</li>
                        <li>are not barred or legally prohibited from using the Services under the laws of Sweden or any other applicable jurisdiction. </li>
                    </ul>
                </p>
                <p>
                <strong>Precedence of Mandatory Law:</strong> In cases where these Terms & Conditions conflict with mandatory consumer law to your detriment, the mandatory law will prevail.
                </p>

                <h2>5. Use of the Services</h2>
                <p><strong>Compliance with Terms:</strong> The Services must be used in accordance with these Terms & Conditions. Any use not expressly permitted or supported by the Services requires Forzifys prior written consent.</p>
                <p><strong>Intended Use and Liability:</strong> You must use the Services only for their intended purposes and in the manner outlined. You are responsible for ensuring your use does not cause harm or inconvenience to Forzify or third parties. This includes, but is not limited to, preventing mass appeals, the spread of computer viruses, and ensuring compliance with laws, government regulations, and decisions. Violations of these Terms & Conditions may be reported to the relevant authorities, associations, or supervisory bodies.</p>
                <p><strong>Service Provision "As Is":</strong> The Services are provided “as is,” meaning they are offered in their current state and may have limitations. Forzify does not guarantee uninterrupted or fault-free operation of the Services, nor does it assure that the information obtained through the Services will always be accurate or reliable.</p>

                <h2>4. Privacy</h2>
                <p><strong>Data Controller:</strong> Forzasys AS acts as the data controller for Personal Data processed when you use the Services. Detailed information about the processing of your personal data and your rights can be found in our Privacy Policy.</p>
                <p><strong>Use of User Data:</strong> By agreeing to these Terms & Conditions, you consent to Forzasys, associated organizations, and suppliers using, modifying, reproducing, selling, developing, publishing, manufacturing, processing, distributing, broadcasting, and sublicensing your User Data in connection with the Services. This includes the right to use your User Data for marketing, market research, quality control, and similar purposes, in line with the applicable Privacy Policy. When processing your User Data in ways that require your explicit consent under Data Protection Legislation, we will seek such consent.</p>

                <h2>5. Content Ownership and Responsibility</h2>
                <p>
                    At Forzasys AS, we prioritize the security of your personal information with utmost seriousness. To safeguard your Personal Data, we have implemented robust security measures including password protection and the use of encryption, among other technical strategies, to prevent unauthorized access and disclosure of the personal information you entrust to us.
                    However, it's important to note that certain Services or when you upload/share content, your personal information and the content you choose to upload or share might become exposed to other users. This exposure could lead to your personal information being read, collected, or used by others. We urge you to be mindful of the personal information you decide to disclose in such scenarios. For instance, if you post your name and email address in a public forum, this information becomes publicly accessible. You bear responsibility for the personal information you elect to share or provide in these instances.
                </p>

                <h2>6. Changes to the Terms & Conditions</h2>
                
                <p><strong>Right to Amend:</strong> Forzasys reserves the right to modify these Terms & Conditions at any time.</p>
                <p><strong>Notification and Review:</strong> In the event of significant changes, Forzasys will provide advance notice to you and offer an opportunity to review the revised Terms & Conditions before you continue to use the Services.</p>
                <p><strong>Acceptance of Changes:</strong> Your continued use of the Services after the implementation of any changes indicates your acceptance of the revised Terms & Conditions. If you do not agree to the changes, your access to the Services may be restricted.</p>

                <h2>7. Liability</h2>
                <p>
                    <strong>Limitation of Liability:</strong> Forzasyss liability for damages is limited to what is stipulated by applicable mandatory law. Therefore, Forzasys is not liable for any damages that may arise from:
                    <ol>
                        <li>The Website or Services not functioning as anticipated;</li>
                        <li>The removal of Content or restriction of your access to the Services;</li>
                        <li>Unauthorized access to your User Profile;</li>
                        <li>Modifications to the Website, Services, or Terms & Conditions, except where such liability is required by mandatory law.</li>
                    </ol>
                </p>
                
                <p><strong>User Liability:</strong> You may be held responsible for any loss or damage incurred by Forzasys, an Association, or others due to your violation of these Terms & Conditions or due to any other negligent or intentional misconduct.</p>

                <h2>8. Access and Account Security</h2>
                <p><strong>User Responsibility for Account Security:</strong> Users are responsible for creating and maintaining the security of their account credentials. This includes selecting a strong password and keeping login information confidential.</p>
                <p><strong>Account Access:</strong> Users must ensure that all persons accessing the Services through their account are aware of these Terms & Conditions and comply with them.</p>
                <p><strong>Unauthorized Access:</strong> Forzasys must be promptly notified of any unauthorized use of your account or any other security breach.</p>

                <h2>9. Links to Third-Party Websites</h2>
                <p><strong>Third-Party Content:</strong> Forzify media management system enables you to upload content to various third-party platforms such as Instagram, TikTok, YouTube, etc. While using this feature, you remain responsible for the content you share.</p>
                <p><strong>Content Responsibility:</strong> You must ensure that any content shared on these platforms complies with both our Terms & Conditions and the terms of the respective third-party platforms. You are responsible for any consequences that arise from the content you post or share, including any violations of intellectual property rights or other legal infringements.</p>
                <p><strong>No Endorsement or Liability:</strong> Providing links to third-party websites does not imply endorsement by Forzasys. We do not assume liability for the content or practices of these third-party sites.</p>
                <p><strong>User Responsibility with Third-Party Platforms:</strong> As you interact with third-party websites, you are subject to their terms and policies. It is essential for you to familiarize yourself with those terms and ensure your content aligns with their guidelines.</p>

            </div>
        </Page>
    );
}