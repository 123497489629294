import "./Match.css";
import React from "react";
import {useSWRConfig} from "swr";
import {getFullPath, useBackend} from "../../../utility/Backend";
import {useParams} from "react-router-dom";
import LoadingScreen from "../../../components/LoadingScreen";
import EventEntry from "../../../components/EventEntry";
import SearchResults from "../../../components/SearchResults";
import {useLoginSession} from "../../../stores/loginSession";
import { useAddVideoToAction, getDateFormat } from "../../../utility/Utilities";
import PlaylistEntry from "../../../components/PlaylistEntry";
import {useUpdateSearchParams} from "../../../utility/Utilities";
import Dropdown from "../../../components/Dropdown";
import Config from "../../../utility/Config";
import GenerateHighlights from "../../../components/GenerateHighlights";
import { MatchTaggingButton } from "../../Tagging/TaggingUtil";
import { useCheckIsAdmin } from "../../../utility/UserGroups";

function Match ({game, tags}) {

    const {token} = useLoginSession();
    const [,isAdmin] = useCheckIsAdmin()
    const [searchParams, updateSearchParams,] = useUpdateSearchParams();

    const [
        selectedVideos, 
        selectAll,
        addVideoToList, 
        removeVideoFromList, 
        clearSelectedList, 
        handleSelectAll 
    ] = useAddVideoToAction();

    const isLive = game.has_live_playlist

    const refreshInterval = isLive ? {
        dedupingInterval: 29900,
        refreshInterval: 30000
    } : {}

    const assetPath = getFullPath(
        `/game/${game.id}/playlists`,
        {filters: "full_game", access_token: token},
        refreshInterval
    );

    const home = game.home_team;
    const away = game.visiting_team;

    const editing = searchParams.get("editing");
    let editingType, editingId;
    if (editing) [editingType, editingId] = editing.split("_");

    const renderEvent = (e, idx) => (
        <EventEntry key={e.id}
                    event={e}
                    active={e.id === editingId && editingType === "event"}
                    tabIndex={20+idx} 
                    selectedVideos={selectedVideos}
                    selectAll={selectAll}
                    addVideoToList={addVideoToList}
                    removeVideoFromList={removeVideoFromList}/>
    );

    const renderPlaylist = (p, idx) => (
        <PlaylistEntry key={p.id}
                       playlist={p}
                       active={p.id === editingId && editingType === "playlist"}
                       tabIndex={20+idx}
                       disableAction 
                       selectedVideos={selectedVideos}
                       selectAll={selectAll}
                       addVideoToList={addVideoToList}
                       removeVideoFromList={removeVideoFromList}
                       tagging/>
    );

    let query = {};

    let team = parseInt(searchParams.get("team"), 10) || null;
    if (!(team === game.home_team.id || team === game.visiting_team.id)) team = null;
    if (team) {
        query["tags"] = {team: {id: team}};
    }
    const teams = {[game.home_team.id]: game.home_team, [game.visiting_team.id]: game.visiting_team}
    const teamFilter = (
        <div>
            <Dropdown
                title="Team"
                special="all"
                fallback="all"
                selected={team}
                options={Object.keys(teams)}
                render={(id) => teams[id].name}
                btnRender={(id) => teams[id].short_name || teams[id].name}
                onChange={v => updateSearchParams({"team": v})}/>
        </div>
    );

    // TODO Unify this with EventListing.js
    let tag = searchParams.get("tag") || null;
    const ignoredTags = ["end of game", "start phase", "end phase", "throw-in", "highlights"];
    const validTags = Object.keys(tags.tags).filter(t => !ignoredTags.includes(t));
    if (tag && !validTags.includes(tag)) tag = null;
    if (tag) {
        query["tags"] = {...query.tags, action: tag};
    }
    const tagFilter = (
        <div>
            <Dropdown
                title="Tag"
                special="all"
                fallback="all"
                selected={tag}
                options={validTags}
                onChange={v => updateSearchParams({"tag": v})}/>
        </div>
    );

    const eventPath = getFullPath(`/game/${game.id}/events`, query);

    return (
        <div className="game-page-container scrollable">
            <div className="game-info">
                <div className="game-info-home">
                    <img src={home.logo_url} alt="logo" className="game-info-logo"/>
                    <div className="team-name">{home.name}</div>
                </div>
                <div className="score-and-time">
                    <div className="game-info-date">{getDateFormat(game.date)}</div>
                    <div className="game-info-score">{game.home_team_goals} - {game.visiting_team_goals}</div>
                    {isLive ? (
                        <div className="live-banner">LIVE</div>
                    ) : (
                        <div className="game-info-phase">{game.phase}</div>
                    )}
                </div>
                <div className="game-info-away">
                    <img src={away.logo_url} alt="logo" className="game-info-logo"/>
                    <div className="team-name">{away.name}</div>
                </div>
            </div>
            {(isAdmin && isLive) && <MatchTaggingButton game={game}/>}
            {/* TODO temporary enable generate highlights for european league */}
            {Config.association === "EL" && <GenerateHighlights game={game}/>}
            {Config.showFullAssetsOnMatchScreen && (
                <>
                    <div className="game-section-header">
                        Assets
                    </div>
                    <div className="game-asset-listing">
                        <SearchResults key={assetPath}
                                       path={assetPath}
                                       render={renderPlaylist}
                                       disableFooter
                                       disableAction/>
                    </div>
                </>
            )}
            <div className="game-section-header">
                Events
            </div>
            <div className="relative">
                <div className="event-filters">
                    {teamFilter}
                    {tagFilter}
                    {/* <div className="filter-controls">
                        <button title="Reset" onClick={clearFilters}><MdOutlineRemoveCircleOutline /></button>
                        {asc && (
                            <button title="Sort descending" onClick={() => setAsc(false)}><FaChevronUp /></button>
                        )}
                        {!asc && (
                            <button title="Sort ascending" onClick={() => setAsc(true)}><FaChevronDown /></button>
                        )}
                    </div> */}
                </div>
            </div>
            <SearchResults 
                key={eventPath} 
                path={eventPath} 
                render={renderEvent} 
                selectedVideos={selectedVideos} 
                clearSelectedList={clearSelectedList}
                selectAll={selectAll}
                handleSelectAll={handleSelectAll}
                refreshInterval={isLive ? 5000 : null}
                enableAutoScroll={isLive}
                showAll
                matchEvents/>
        </div>
    );
}

export default function MatchWrapper () {

    const {gameId} = useParams();

    // We use the cache purely to get instant rendering when clicking on a search entry
    const {cache} = useSWRConfig();
    let fallback = cache.get("viewed_match");
    if (fallback && fallback.id !== gameId) fallback = undefined;

    const {data: tags} = useBackend("/tag", undefined, {revalidateIfStale: false, revalidateOnFocus: false});

    const {data, error} = useBackend(
        "/game/" + gameId,
        undefined,
        {fallbackData: fallback});

    if (data && tags) return <Match game={data} tags={tags} />

    if (error) return (
        <div className="is-loading error-msg flex-vertically-centered">
            {(error.status === 404) && (
                <span>Match {gameId} could not be found</span>
            )}
            {(error.status !== 404) && (
                <span>Unknown error occurred trying to fetch data from server</span>
            )}
        </div>
    );

    return (
        <div className="is-loading flex-vertically-centered">
            <LoadingScreen />
        </div>
    );
}
