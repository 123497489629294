import classNames from "classnames";
import React, {useRef, useState} from "react"
import DatePicker from "react-datepicker";
import {ImCalendar} from "react-icons/im";

function UploadTimeOfRecordingSection ({timestamp, onChange, hideNowBtn=false}) {

    const datePickerRef = useRef(null)

    const [datePickerFocus, setDatePickerFocus] = useState(false)

    const handleDatePickerFocus = () => {
        if (datePickerFocus) {
            setDatePickerFocus(false)
            datePickerRef.current.blur()
        }
        else datePickerRef.current.focus()
    }

    const handleSetManualTime = (e) => {
        e.stopPropagation()
        onChange(new Date())
    }

    const nextYear = new Date().getUTCFullYear() + 1
    const nextEndOfYear = new Date(new Date().setUTCFullYear(nextYear, 12, 0))

    return (
        <div onClick={handleDatePickerFocus} className={classNames("time-of-recording-cont", {"between": hideNowBtn} )}>
            <label onClick={(e) => e.stopPropagation()} ref={datePickerRef} className="time-of-recording-picker">
                <DatePicker 
                    selected={timestamp}
                    onChange={(date) => onChange(date)}
                    dateFormat="yyyy-MM-dd, HH:mm"
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={34}
                    maxDate={nextEndOfYear}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    onFocus={() => setDatePickerFocus(true)}
                />
            </label>
            <ImCalendar/>
            {!hideNowBtn && (
                <button type="button" onClick={(e) => handleSetManualTime(e)} className="smaller-font-btn">
                    Set to Now
                </button>
            )}
        </div>
    )
}

export default UploadTimeOfRecordingSection