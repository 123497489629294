import React, { useEffect, useState } from "react";
import Config from "../utility/Config";
import CustomModal from "../components/CustomModal";
// import VideoExport from "./VideoExport";
import {formatTimecode} from "../utility/Utilities";
import {useLoginSession} from "../stores/loginSession";
import "./css/VideoAction.css";
import { FaTimes } from "react-icons/fa";

function VideoActionModal ({
    state,
    videosForAction,
    removeVideoFromModal,
    resetActionModal,
    exportAndEmail,
    listUnListFeature,
    toVotingBallot,
    clearWarning,
}) {

    const {user, token} = useLoginSession();

    const [isDownloading, setIsDownloading] = useState(false);
    // TODO remove all FTP export related thing (removed in new ftp server branch)
    const [format, setFormat] = useState("MP4");
    const [audio, setAudio] = useState("All");
    const [exportAction, setExportAction] = useState("directDownload");
    const [server, setServer] = useState(null);

    const actionWarning = (duration, is_private, featured) => {
        if (state.type === "download" && duration > 300000){
            return "This video is longer than 5 minutes"
        }
        if (state.type === "unList" && is_private === true){
            return "This video is already unlisted"
        }
        if (state.type === "list" && is_private === false){
            return "This video is already listed"
        }
        if (state.type === "feature" && featured === true){
            return "This video is already featured"
        }
        return null;
    }

    const downloadVideo = (videoUrl, id) => {
        const url = videoUrl
            .replace("Manifest.m3u8","50000000/" + id + ".mp4")
            .replace(/https:\/\/[^.]*api\..*\.com\//, Config.api)
        const a = document.createElement("a");
        a.href = url + "?access_token=" + token;
        a.download = id + ".mp4";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setIsDownloading(true);
    }

    const confirmAction = (type) => {
        if (type === "download"){
            exportAndEmail(exportAction, format, audio, server, user.email);
            return;
        }
        if (type === "voting") {
            toVotingBallot();
            return;
        }
        if (type === "unList" || "list" || "feature" || "unFeature"){
            listUnListFeature();
            return;
        }
        return null
    }

    useEffect(() => {
        const removeIsDownloading = setTimeout(() => {
            setIsDownloading(false);
        }, 5000);
        return () => clearTimeout(removeIsDownloading)
    }, [isDownloading]);

    const videosForActionList = videosForAction.map((video) => {
        const {
            id, 
            thumbnail_url, 
            video_url, 
            description, 
            duration_ms, 
            is_private, 
            featured
        } = video;

        const actionWarningMsg = actionWarning(duration_ms, is_private, featured);
        const formattedDuration = formatTimecode(duration_ms/1000);
        const aboveFiveMins = duration_ms > 300000;

        return (
            <div key={id} className="selected-video-single">
                <img src={thumbnail_url} alt="thumbnail" className="selected-thumbnail"/>
                <div className="selected-details">
                    <div className="selected-duration-desc">
                        <div className="selected-duration">{formattedDuration}</div>
                        <div className="selected-desc">{description}</div>
                    </div>
                    {actionWarningMsg && <div className="selected-warning">{actionWarningMsg}</div>}
                </div>
                {(state.type === "download" && exportAction === "directDownload" ) && (
                    <button type="button" disabled={isDownloading || aboveFiveMins} className="direct-download green-hover-btn" onClick={() => downloadVideo(video_url, id)}>
                        {!isDownloading? "Direct download" : "Please wait"}
                    </button>
                )}
                <FaTimes className="selected-delete" onClick={() => removeVideoFromModal(id)}/>
            </div>
        )
    });

    return (
        <CustomModal isOpen onRequestClose={resetActionModal}>
            <div className="video-action-modal">
                <div className="action-modal-title">{state.title}</div>
                <div className="selected-video-cont">{videosForActionList}</div>
                {/* {state.type === "download" && (
                    <VideoExport
                        state={state}
                        format={format}
                        audio={audio}
                        exportAction={exportAction}
                        server={server}
                        clearWarning={clearWarning}
                        setFormat={setFormat}
                        setAudio={setAudio}
                        setExportAction={setExportAction}
                        setServer={setServer}/>
                )} */}
                <div className="confirm-cancel-btn-cont center">
                    {state.type !== "download" && (
                        <button 
                            type="button" 
                            className="green-hover-btn"
                            disabled={exportAction === "directDownload" && state.type === "download"}
                            onClick={() => confirmAction(state.type)}
                            >
                            Confirm
                        </button>
                    )}
                    <button 
                        type="button" 
                        onClick={resetActionModal}>
                        Cancel
                    </button>
                </div>
            </div>
        </CustomModal>
    )
}

export default VideoActionModal;