import React, {useState, useEffect} from "react"
import Config from "../../utility/Config";
import { useLoginSession } from "../../stores/loginSession";
import { useBackend } from "../../utility/Backend";
import Backend from "../../utility/Backend";
import CustomModal from "../../components/CustomModal";
import ConfirmModal from "../../components/ConfirmModal";
import classNames from "classnames";
import { useMutateByRegex } from "../../utility/Utilities";
import ExportsTable from "./ExportsTable";
import "./Exports.css"
import {BsInfoCircle} from "react-icons/bs";
import {FaTrashAlt} from "react-icons/fa";
import Shares from "./Shares";
import ShareVideo from "../ShareVideo/ShareVideo";
// import ShareVideo from "../ShareVideo/ShareVideo";

function Exports ({isOpen, onClose}) {
    
    const {token} = useLoginSession()
    const mutateByRegex = useMutateByRegex()

    const isPPro = Config.premiereProPlugin

    const [tableType, setTableType] = useState("exports")
    const [downloadIndex, setDownloadIndex] = useState([])
    const [exportToBeRemoved, setExportToBeRemoved] = useState(null)
    const [shareVideo, setShareVideo] = useState(null)
    const [fetchExportMoreFrequent, setFetchExportMoreFrequent] = useState(false)

    // this useEffect is for disabling the button for 5 secs in case the user clicks download 
    // and the status does not change to "processing straight away"
    useEffect(() => {
        const removeIsDownloading = setTimeout(() => {
            setDownloadIndex([]);
        }, 5000)
        return () => clearTimeout(removeIsDownloading)
    }, [downloadIndex])

    const apiQuery = {access_token: token, count: 25}
    const fetchInterval = {
        // 5 secs when there's ongoing export, 20 secs when none
        dedupingInterval: fetchExportMoreFrequent ? 4900 : 19900,
        refreshInterval: fetchExportMoreFrequent ? 5000 : 20000,
    }

    const {data} = useBackend("/video_export", apiQuery, fetchInterval)

    let exports = data?.video_exports || []
    
    const hasRunningExport = exports.some((e) => !e.download_link)
    useEffect(() => {
        setFetchExportMoreFrequent(hasRunningExport)
    }, [hasRunningExport])

    if (!data) return null

    const onDownload = (event, mp4url, description, index) => {
        if (event) event.stopPropagation()
        setDownloadIndex([...downloadIndex, index]);

        // AdobePPro
        if (isPPro) {
            event.preventDefault();

            // set name to the frist 40 characters of the description if set, otherwise
            // generate a random string of 10 characters as name of the export
            let name = description ? description.substring(0, 40).replace(/\s/g, "_") : Math.random().toString(36).substring(2, 15);

            const date = new Date();
            const dateString = date.getFullYear().toString().slice(-2) + "" +
                                (date.getMonth() + 1).toString().padStart(2, "0") + "" +
                                date.getDate().toString().padStart(2, "0") + "T" +
                                date.getHours().toString().padStart(2, "0") + "" +
                                date.getMinutes().toString().padStart(2, "0") + "_";

            window.top.postMessage({
                type: "ppro",
                mp4url: mp4url,
                token: "XXX", // Note: We don't need to get a token since the exports are public
                name: dateString + name
            }, "*");
            return;
        }
    }

    const removeExport = () => {
        const query = {access_token: token}
        Backend.delete("/video_export/" + exportToBeRemoved?.id, query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to DELETE", error)
                } else {
                    console.log("export removed")
                    mutateByRegex(/^\/video_export/)
                }
            });
    }

    const removeExportModal = (
        <ConfirmModal 
            isOpen 
            onClose={() => setExportToBeRemoved(null)} 
            onConfirm={removeExport} 
            confirmText = "Remove"
            >
            <div className="confirm-icon-message">
                <div className="confirm-icon"><FaTrashAlt/></div>
                <div className="confirm-title">Do you really want to remove {exportToBeRemoved?.description} from export?</div>
            </div>
        </ConfirmModal>
    );

    const renderExports = tableType === "exports" && (
        <div className="exports-cont">
            {exports.length > 0? 
                <ExportsTable 
                    exports={exports} 
                    onDownload={onDownload} 
                    downloadIndex={downloadIndex} 
                    setShareVideo={setShareVideo}
                    setExportToBeRemoved={setExportToBeRemoved}
                    /> 
                : <div className="no-exports-table">No exports to show</div>
            }
            <div className="confirm-cancel-btn-cont center">
                <button type="button" onClick={onClose}>Close</button>
                <div className="exports-table-info">
                    <BsInfoCircle/>
                    <i> This table lists your queued and past exports.
                        You may close and check back later at any point. 
                        Download link will be valid for a couple of weeks.
                    </i>
                </div>
            </div>
        </div>
    )

    const renderShares = tableType === "shares" && <Shares onClose={onClose}/>
    
    return (
        <CustomModal isOpen={isOpen} onRequestClose={onClose} className="wider">
            <div className="exports-and-shares">
                <div className="exports-and-shares-filter">
                    <div onClick={() => setTableType("exports")} className={classNames("exports-table-filter" ,{"active": tableType === "exports"})}>Exports</div>
                    <div onClick={() => setTableType("shares")} className={classNames("shares-table-filter" ,{"active": tableType === "shares"})}>Shares</div>
                </div>
                {renderExports}
                {renderShares}
            </div>
            <ShareVideo 
                shareVideo={shareVideo} 
                onClose={() => setShareVideo(false)} 
                openShareTable={() => setTableType("shares")}
                />
            {exportToBeRemoved && removeExportModal}
        </CustomModal>
    )
}

export default Exports