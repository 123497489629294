import React, { useState } from "react"
import { useLoginSession } from "../../stores/loginSession";
import { useBackend } from "../../utility/Backend";
import { tagToIcon } from "../../utility/Utilities";
import Backend from "../../utility/Backend";
import CustomModal from "../../components/CustomModal"
import { useFeedbackMessage } from "../../stores/FeedbackMessage";
import "./UserGroupInfo.css"
import classNames from "classnames";

function ManageAllowedEvents ({userGroupId, userGroupEvents, onMutate, onClose}) {

    const {token} = useLoginSession()
    const query = {access_token: token}
    const {showFeedback} = useFeedbackMessage()

    const [selectedTags, setSelectedTags] = useState(userGroupEvents)

    const {data: tagsData} = useBackend("/tag", undefined, {revalidateIfStale: false, revalidateOnFocus: false});
    
    if (!tagsData) return null

    const tags = tagsData?.tags || []
    const validTags = Object.keys(tags).map(t => t)
    selectedTags.sort((a, b) => a.localeCompare(b))

    const handleSelectTag = (tag) => {
        if (selectedTags.includes(tag)) {
            const filteredTags = selectedTags.filter((t) => t !== tag)
            setSelectedTags(filteredTags)
        } else {
            setSelectedTags([...selectedTags, tag])
        }
    }

    const onSubmitEvents = () => {
        const events = {actions: selectedTags}
        console.log("submitting events", JSON.stringify(events));
        Backend.put("/usergroup/" + userGroupId + "/event_actions/", JSON.stringify(events), query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to PUT", error)
                    showFeedback("warning", "Failed to submit allowed events, " + error)
                } else {
                    console.log("new event actions submitted")
                    showFeedback("success", "Allowed events updated")
                    onMutate()
                }
            });
        onClose()
    }

    return (
        <CustomModal isOpen onRequestClose={onClose} className="medium">
            <div className="allowed-tags-cont">
                <div className="data-info-head-title">Allowed tags</div>
                <div className="available-allowed-tags-cont">
                    {validTags.map((t) => {
                        return (
                            <div
                                key={t}
                                onClick={() => handleSelectTag(t)}
                                className={classNames("available-tag-single", {
                                    "allowed": selectedTags.includes(t)
                                })}>
                                <div className={classNames("available-tag-single-icon", {
                                    "yellow-card": t === "yellow card"
                                    })}>
                                    {tagToIcon(t)}
                                </div>
                                {t}
                            </div>
                        )
                    })}
                </div>
                <div className="confirm-cancel-btn-cont between">
                    <button type="button" onClick={onSubmitEvents} className="green-btn">Confirm</button>
                    <button type="button" onClick={onClose}>Cancel</button>
                </div>
            </div>
        </CustomModal>
    )
}

export default ManageAllowedEvents