import React, {useState, useRef, useMemo} from "react"
import { useMediaQuery } from "react-responsive";
import { useCheckIsAdmin } from "../../utility/UserGroups";
import { capitalizeFirstLetter, ClickOutside } from "../../utility/Utilities";
import { useBackend, infrequentCacheConf } from "../../utility/Backend";
import ThumbnailSelector from "../../components/ThumbnailSelector";
import { paramTypeDict } from "./TaggingUtil";
import classNames from "classnames";
import { DisplayPassedTaggingTime } from "./TaggingUtil";
import { AdjustTaggingTime } from "./TaggingUtil";
import "./Tagging.css"
import {FiCheckSquare, FiSquare} from "react-icons/fi";
import {BsInfoCircle} from "react-icons/bs";
import { IoMdArrowDropright, IoMdArrowRoundBack } from "react-icons/io";

function TagParamProperties ({taggingState, taggingDispatch, game, tagsData}) {

    const [editingParamIndex, setEditingParamIndex] = useState(null)

    const mobileDevice = useMediaQuery({maxWidth: 768})
    const taggingParamRef = useRef(null)
    ClickOutside(taggingParamRef, setEditingParamIndex, true)

    const tags = tagsData?.tags || []
    const tagsTypes = tagsData?.types || []
    const tagParam = tags[taggingState.tag] || []
    const editingParam = tagParam[editingParamIndex]

    const {data: gamePlayers} = useBackend(game? ("/game/" + game.id + "/players") : null, {}, infrequentCacheConf)
    const homeTeamPlayers = gamePlayers?.home_team.players || []
    const awayTeamPlayers = gamePlayers?.visiting_team.players || []
    const homeTeam = game.home_team
    const awayTeam = game.visiting_team
    const teams = [homeTeam, awayTeam]

    // All players that are selected and are in tagging state
    const playersInTaggingState = Object.keys(taggingState).reduce((list, key) => {
        if (taggingState[key]?.name) list.push({param: key, id: taggingState[key].id})
        return list
    }, [])

    const optionsInTaggingState = Object.keys(taggingState).reduce((list, key) => {
        if (typeof taggingState[key] === "string") list.push({param: key, value: taggingState[key]})
        return list
    }, [])

    const onDispatchTagParam = (payload) => {

        const paramName = paramTypeDict[editingParam.name] || editingParam.name

        // remove the value if selecting the same param
        if (taggingState[paramName] === payload) {
            taggingDispatch({type: paramName, payload: ""})
        } else {
            taggingDispatch({type: paramName, payload: payload})
        }

        if (mobileDevice) {
            setEditingParamIndex(null)
        } else {
            // auto jump to next param selection after selecting a param value
            if (tagParam.length-1 > editingParamIndex) {
                setEditingParamIndex(editingParamIndex + 1)
            } else {
                setEditingParamIndex(null)
            }
        }
    }

    const onSelectEditingParam = (idx) => {
        if (editingParamIndex === idx) setEditingParamIndex(null)
        else setEditingParamIndex(idx)
    }

    function RenderTagParam () {
        return (
            <div className="tagging-tag-param">
                {tagParam.map((p, idx) => {
                    let paramValue
                    if (p.type === "team") {
                        paramValue = taggingState[p.name]?.name
                    } 
                    else if (p.type === "player") {
                        const tagParamName = taggingState[paramTypeDict[p.name]] || taggingState[p.name] || {}
                        const shirtNumber = tagParamName.shirt_number
                        const playerName = tagParamName.name
                        if (shirtNumber && playerName) {
                            paramValue = `${shirtNumber}, ${playerName}`
                        }
                    } else {
                        paramValue = taggingState[paramTypeDict[p.name]] || taggingState[p.name]
                    }
                    
                    return (
                        <div 
                            key={p.name} 
                            onClick={() => onSelectEditingParam(idx)}
                            className={classNames("tag-param-single", {"active": editingParam?.name === p.name})}
                            >
                            <div className="tag-param-single-title">{capitalizeFirstLetter(p.name)}</div>
                            <div className="tag-param-single-value">{paramValue}</div>
                            <IoMdArrowDropright/>
                        </div>
                    )
                })}
            </div>
        )
    }

    function RenderTagParamOptions () {

        const playersList = () => {
            if (!taggingState.team) return []
            const homeTeamSelected = taggingState.team.id === homeTeam.id
            const awayTeamSelected = taggingState.team.id === awayTeam.id

            if (homeTeamSelected) {
                if (["offending player", "keeper"].includes(editingParam.name)) {
                    return awayTeamPlayers
                } else {
                    return homeTeamPlayers
                }
            }
            if (awayTeamSelected) {
                if (["offending player", "keeper"].includes(editingParam.name)) {
                    return homeTeamPlayers
                } else {
                    return awayTeamPlayers
                }
            }
            else return []
        }

        const tagParamOptions = () => {
        
            if (!editingParam) return
            if (editingParam.type === "none") return

            if (editingParam.type === "team") return (
                <div className="tagging-param-options team">
                    {teams.map((team) => {
                        return (
                            <div 
                                key={team.id} 
                                onClick={() => onDispatchTagParam(team)}
                                className={classNames("param-option-single", {"active": taggingState.team?.id === team.id})}
                                >
                                <img src={team.logo_url} className="param-option-team-logo" alt="team logo" />
                                <div className="param-option-team-name">{team.name}</div>
                            </div>
                        )
                    })}
                </div>
            )
            
            if (editingParam.type === "player") {
                const playersInList = playersList()
                if (playersInList.length === 0) return (
                    <div className="tagging-param-options">
                        <div className="select-team-show-players">Select team to show players</div>
                    </div>
                )
                return (
                    <div className="tagging-param-options">
                        {playersInList.map((player) => {
                            const playerInTaggingState = playersInTaggingState.find((p) => p.id === player.id)
                            const paramName = paramTypeDict[editingParam.name] || editingParam.name
                            const playerSelectedInList = playerInTaggingState?.param === paramName
                            return (
                                <div 
                                    key={player.id} 
                                    onClick={() => onDispatchTagParam(player)}
                                    className={classNames("param-option-single", {"active": playerSelectedInList})}
                                    >
                                    <div className="param-option-player-number">{player.shirt_number}</div>
                                    <div className="param-option-player-name">{player.name}</div>
                                    {playerInTaggingState && (
                                        <div className="param-option-player-selected">{capitalizeFirstLetter(playerInTaggingState.param).replace("_", " ")}</div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                )
            }
    
            const tagTypeOptions = tagsTypes[editingParam.type].options
    
            if (tagTypeOptions.length !== 0) return (
                <div className="tagging-param-options">
                    {tagTypeOptions.map((option) => {
                        const optionSelectedInTaggingState = optionsInTaggingState.find((o) => o.value === option)
                        const paramName = paramTypeDict[editingParam.name] || editingParam.name
                        const optionSelectedInList = optionSelectedInTaggingState?.param === paramName
                        return (
                            <div 
                                key={option} 
                                onClick={() => onDispatchTagParam(option)}
                                className={classNames("param-option-single", {"active": optionSelectedInList})}
                                >
                                {capitalizeFirstLetter(option)}
                            </div>
                        )
                    })}
                </div>
            )
            else return null
        }

        return (
            <div className={classNames("tagging-param-options-cont", {"show": editingParam})}>
                {(mobileDevice && editingParam) && (
                    <div className="param-options-mobile-header">
                        <div className="param-options-mobile-header-title">{editingParam.name}</div>
                        <div onClick={() => setEditingParamIndex(null)} className="param-options-mobile-header-back">
                            <IoMdArrowRoundBack/>
                            Back
                        </div>
                    </div>
                )}
                {tagParamOptions()}
            </div>
        )
    }

    return (
        <div className="tagging-params-cont">
            <div className="tagging-section-title">Event Properties</div>
            <div ref={taggingParamRef} className="tagging-params-and-options">
                <RenderTagParam/>
                <RenderTagParamOptions/>
            </div>
        </div>
    )
}

function AssetTaggingForm ({taggingState, taggingDispatch, playlist, game, tagsData, onCreateTag}) {

    const [,isAdmin] = useCheckIsAdmin()
    const titleRef = useRef(null)
    const tags = tagsData?.tags || []
    const tagParam = tags[taggingState.tag] || []

    const videoAssetName = playlist?.events[0]?.video_asset_name 

    const clipsForThumbnails = useMemo(() => [{
        from: taggingState.from_timestamp,
        to: taggingState.to_timestamp,
        assetName: videoAssetName,
    }], [taggingState.from_timestamp, taggingState.from_timestamp])
    
    const setThumbnail = (newUrl) => {
        if (typeof newUrl === "object") taggingDispatch({type: "thumbnail_url", payload: newUrl})
        else taggingDispatch({type: "thumbnail_url", payload: newUrl})
    }

    return (
        <form className="tagging-form" onSubmit={(e) => e.preventDefault()}>
            <div className="confirm-cancel-btn-cont between">
                <button onClick={onCreateTag} disabled={!taggingState.tag} className="green-btn">
                    Create Event
                </button>
                <button onClick={() => taggingDispatch({type: "reset"})}>
                    Cancel
                </button>
            </div>
            {taggingState.tag && (
                <div className="tag-details-cont">
                    <div className="tagging-section-title">Event details</div>
                    <div className="tag-details">
                        <div className="tag-details-single">
                            <div className="tag-details-title">Event name</div>
                            <div className="tag-name">{capitalizeFirstLetter(taggingState.tag)}</div>
                        </div>
                        <div className="tag-details-single">
                            <div className="tag-details-title">Tagged at</div>
                            <div className="tagged-time">{new Date(taggingState.time).toLocaleTimeString()}</div>
                            <DisplayPassedTaggingTime taggingTime={taggingState.time}/>
                        </div>
                    </div>
                    <AdjustTaggingTime taggingState={taggingState} taggingDispatch={taggingDispatch}/>
                </div>
            )}
            {tagParam.length !==0 && (
                <TagParamProperties 
                    taggingState={taggingState} 
                    taggingDispatch={taggingDispatch}
                    game={game}
                    tagsData={tagsData}/>
            )}
            <div ref={titleRef} className="input-container">
                <label className="input-title">Title</label>
                <input
                    type="text"
                    value={taggingState.description}
                    onChange={(e) => taggingDispatch({type: "description", payload: e.target.value})}>
                </input>
            </div>
            {isAdmin && (
                <div className="input-container">
                    <label className="input-title">Published</label>
                    <>
                        <div
                            onClick={() => taggingDispatch({type: "is_private", payload: true})}
                            className={classNames("option-checkbox", {"active": taggingState.is_private})}>
                            {taggingState.is_private?
                                <FiCheckSquare className="check-box-icon"/> :
                                <FiSquare className="check-box-icon"/>}
                            <div className="option-box-title">
                                Not yet published
                                <div className="option-extra-message">
                                    <BsInfoCircle className="info-icon"/> video will be unlisted (can be changed later)
                                </div>
                            </div>
                        </div>
                        <div
                            onClick={() => taggingDispatch({type: "is_private", payload: false})}
                            className={classNames("option-checkbox", {"active": !taggingState.is_private})}>
                            {!taggingState.is_private?
                                <FiCheckSquare className="check-box-icon"/> :
                                <FiSquare className="check-box-icon"/>}
                            <div className="option-box-title">
                                Published
                            </div>
                        </div>
                    </>
                </div>
            )}
            <div className="input-container">
                <label className="input-title">Thumbnail</label>
                <ThumbnailSelector
                    selected={taggingState.thumbnail_url?.imageUrl || taggingState.thumbnail_url}
                    onChange={setThumbnail}
                    playlistClips={clipsForThumbnails}
                    />
            </div>
            <div className="confirm-cancel-btn-cont between">
                <button onClick={onCreateTag} disabled={!taggingState.tag} className="green-btn">
                    Create Event
                </button>
                <button onClick={() => taggingDispatch({type: "reset"})}>
                    Cancel
                </button>
            </div>
        </form>
    )
}

export default AssetTaggingForm