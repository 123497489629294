import React, {useState} from "react"
import { useBackend } from "../../utility/Backend";
import { useLoginSession } from "../../stores/loginSession";
import LoadingScreen from "../../components/LoadingScreen";
import Backend from "../../utility/Backend";
import { Paging } from "../../utility/Paging";
import { getDateFormat, useMutateByRegex } from "../../utility/Utilities";
import { useCheckIsAdmin } from "../../utility/UserGroups";
import ConfirmModal from "../../components/ConfirmModal";
import "./Shares.css"
import {FaFacebook, FaInstagram, FaTiktok, FaTrashAlt} from "react-icons/fa";

function Shares ({onClose}) {

    const [isFsAdmin,] = useCheckIsAdmin()
    const [sharesPage, setSharesPage] = useState(1)
    const [shareToBeRemoved, setShareToBeRemoved] = useState(null)

    const itemPerPage = 8
    const from = sharesPage === 1 ? 0 : (sharesPage-1) * itemPerPage

    const fetchSharesProps = {
        from: from,
        count: itemPerPage,
    }

    const {data} = useBackend("/socials", fetchSharesProps , {
        dedupingInterval: 2900,
        refreshInterval: 3000,
    })

    const {token} = useLoginSession()
    const mutateByRegex = useMutateByRegex()

    if (!data) return (
        <div className="shares-loading"><LoadingScreen/></div>
    )
    
    const totalShares = data.total
    const totalPage = Math.ceil(totalShares / itemPerPage)
    const shares = data.shared_videos || []

    if (shares.length === 0) return (
        <div className="no-shared-videos">No shared videos</div>
    )

    const removeShare = () => {
        const query = {access_token: token}
        Backend.delete("/socials/" + shareToBeRemoved?.id, query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to DELETE", error)
                } else {
                    console.log("export removed")
                    mutateByRegex(/^\/socials/)
                }
            });
    }
    
    const removeShareModal = shareToBeRemoved && (
        <ConfirmModal 
            isOpen 
            onClose={() => setShareToBeRemoved(null)} 
            onConfirm={removeShare} 
            confirmText = "Remove"
            >
            <div className="confirm-icon-message">
                <div className="confirm-icon"><FaTrashAlt/></div>
                <div className="confirm-title">Do you really want to remove {shareToBeRemoved?.description || "this"} share record?</div>
            </div>
        </ConfirmModal>
    );

    // TODO use the percentage progress component
    const showShareStatus = (status, progress=null) => {
        
        if (status === "PROCESSING") {
            return (
                <div className="posting-loading">
                    <div className="posting-loading-message">
                        <FaInstagram/>
                        Processing
                        <div className="share-loading-dot">.</div>
                        <div className="share-loading-dot">.</div>
                        <div className="share-loading-dot">.</div>
                    </div>
                    <div className="posting-loading-message-extra">This may take a few minutes</div>
                </div>
            )
        }

        if (status === "Uploading") {
            const uploadingProgress = progress+"%"
            return (
                <div className="posting-loading">
                    <div className="posting-loading-message">
                        <FaInstagram/>
                        Uploading
                        <div className="share-loading-dot">.</div>
                        <div className="share-loading-dot">.</div>
                        <div className="share-loading-dot">.</div>
                    </div>
                    <div className="posting-loading-progress">
                        <div className="posting-loading-bar" style={{width: uploadingProgress}}></div>
                        <div className="posting-loading-number">{progress}%</div>
                    </div>
                </div>
            )
        }

        if (status === "Published") {
            return (
                <div className="posting-success-message">
                    Published
                </div>
            )
        }

        if (status === "Upload Failed" || status === "Failed") {
            return (
                <div className="posting-failed-message">
                    Failed
                </div>
            )
        }

        else return (
            <div className="posting-loading">
                <div className="posting-loading-message">
                    Processing
                    <div className="share-loading-dot">.</div>
                    <div className="share-loading-dot">.</div>
                    <div className="share-loading-dot">.</div>
                </div>
                <div className="posting-loading-message-extra">This may take a few minutes</div>
            </div>
        )
    }
    
    const sharesTable = (
        <div className="exports-table scrollable">
            <div className="table-header-cont sticky">
                <div className="share-submitted">Submitted</div>
                <div className="share-platform">Platform</div>
                <div className="share-media">Media type</div>
                <div className="share-creator">Creator</div>
                <div className="share-url">Link</div>
                <div className="share-status">Status</div>
                <div className="share-delete">DEL</div>
            </div>
            {shares.map((s) => {
                return (
                    <div key={s.id} className="shared-video-single">
                        <div className="share-submitted">{getDateFormat(s.submitted, true, true)}</div>
                        <div className="share-platform">{s.platform}</div>
                        <div className="share-media">{s.media_type}</div>
                        <div className="share-creator">{s.creator.name}</div>
                        <div className="share-url data">
                            {s.publish_url? <a href={s.publish_url} target="blank" className="link-to-post">Link to post</a> : "-"}
                        </div>
                        <div className="share-status data">{showShareStatus(s.status, s.progress)}</div>
                        {isFsAdmin && (
                            <div className="share-delete icon">
                                <FaTrashAlt onClick={() => setShareToBeRemoved(s)}/>
                            </div>
                        )}
                    </div>
                )
            })}
        </div>
    )

    return (
        <div className="shares-cont">
            {sharesTable}
            <Paging page={sharesPage} pageCount={totalPage} onChange={(p) => setSharesPage(p)}/>
            <br />
            <div className="confirm-cancel-btn-cont center">
                <button type="button" onClick={onClose}>Close</button>
            </div>
            {removeShareModal}
        </div>
    )
}

export default Shares