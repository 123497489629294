import React, { useEffect, useReducer, useState } from "react";
import LoadingScreen from "../../../components/LoadingScreen";
import ConfirmModal from "../../../components/ConfirmModal";
import "./MetadataSection.css";
import "../../../DataInfoUtility.css"
import Backend from "../../../utility/Backend";
import {useLoginSession} from "../../../stores/loginSession";
import { useFeedbackMessage } from "../../../stores/FeedbackMessage";
import {duplicateSearchParamsSubset, useUpdateSearchParams, getDateFormat, hockeyTimeFormat, capitalizeFirstLetter} from "../../../utility/Utilities";
import {useNavigate} from "react-router-dom";
import {useSWRConfig} from "swr";
import { useCheckIsAdmin } from "../../../utility/UserGroups";
import { useMutateByRegex } from "../../../utility/Utilities";
import Config from "../../../utility/Config";
import {FaTrashAlt} from "react-icons/fa";

function initReducer (event) {
    return {
        edits: null,
        isEdited: false,

        wall_clock_time: event.wall_clock_time,
        tag: event.tag.action
    }
}

function EventMetadataSection ({event, game, onMutate, hide}) {

    const {token} = useLoginSession();
    const {showFeedback} = useFeedbackMessage();
    const [,isFsAdmin] = useCheckIsAdmin()
    const mutateByRegex = useMutateByRegex()
    // TODO Add possibility to edit tags & wallclocktime. Until then, this file is very overengineered

    function reducer (state, action) {
        switch (action.type){
            case "time": {
                const edits = {...state.edits, wall_clock_time: action.value};
                return {...state, isEdited: true, edits, wall_clock_time: action.value};
            }
            case "tag": {
                const edits = {...state.edits, tag: action.value};
                return {...state, isEdited: true, edits, tag: action.value};
            }
            case "save": {
                const newPlaylist = {...event, ...state.edits};
                return initReducer(newPlaylist);
            }
            case "discard": return initReducer(event);
            default: return state;
        }
    }

    const [state, dispatch] = useReducer(reducer, event, initReducer)
    const [deletingEvent, setDeletingEvent] = useState(false)

    useEffect(() => {
        // Event may get refreshed from backend after initial render
        // Typically right after page render though, so should be safe
        dispatch({type: "discard"});
    }, [event]);

    //TODO when we start editing this
    // eslint-disable-next-line no-unused-vars
    function onSubmit () {
        const edits = state.edits;

        if (!edits) {
            dispatch({type: "discard"});
            return false;
        }

        // Validation for timestamp

        const query = {access_token: token};
        console.log("Saving data:", JSON.stringify(edits))
        console.log(JSON.stringify(event.tag));
        Backend.put("/event/" + event.id, JSON.stringify(edits), query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to PUT", error);
                    dispatch({type: "discard"});
                    showFeedback("warning", "Failed to edit metadata, " +  error);
                } else {
                    onMutate({...event, ...edits});
                    dispatch({type: "save"});
                    showFeedback("success", "Metadata edited successfully!");
                }
            });
        return false;
    }

    const navigate = useNavigate();
    const {cache} = useSWRConfig();
    const [searchParams,, resetAllSearchParamsExcept] = useUpdateSearchParams();

    // TODO hide event metadata when creating subclip
    if (hide) return null

    function navigateToMatch (match) {
        cache.set("viewed_match", match);
        const search = duplicateSearchParamsSubset(["editing"], searchParams);
        navigate(`/library/match/${match}${search}`);
    }

    const gameTime = Math.ceil(event.game_time / 60);
    if (!game) return <LoadingScreen />

    const onDeleteEvent = () => {
        Backend.delete("/event/" + event.id, {access_token: token})
            .then(({error}) => {
                if (error) {
                    console.error("Failed to DELETE", error);
                } else {
                    resetAllSearchParamsExcept(["season", "videotype", "page"]);
                    mutateByRegex(/^\/(playlist)|(event)|(game\/\d+\/events)/)
                    showFeedback("success", "Event deleted successfully");
                    console.log("delete success");
                }
            })
    }

    const gameText = `${getDateFormat(game.date)}: ${game.home_team.name} vs ${game.visiting_team.name} (${game.home_team_goals}-${game.visiting_team_goals})`;
    const isShl = Config.association === "SHL"
    const shlGameTime = isShl && hockeyTimeFormat(event.tag)

    const deleteEventModal = deletingEvent && (
        <ConfirmModal
            isOpen
            onClose={() => setDeletingEvent(false)}
            onConfirm={onDeleteEvent}
            cancelText="Cancel"
            confirmText="Delete">
            <div className="confirm-icon-message">
                <div className="confirm-icon"><FaTrashAlt/></div>
                <div className="confirm-title">Are you sure you wish to delete {event.playlist?.description} event?</div>
            </div>
        </ConfirmModal>
    )

    return (
        <div className="data-info-cont">
            <div className="data-info-head-title">Event Information</div>
            <form onSubmit={(e) => e.preventDefault()}>
                <div className="data-info">
                    <div className="data-info-title">Match</div>
                    {/*TODO Make this an <a> tag*/}
                    <div className="data-info-value match-link" onClick={() => navigateToMatch(event.game_id)}>
                        {gameText}
                    </div>
                </div>
                <div className="data-info">
                    <div className="data-info-title">Type</div>
                    <div className="data-info-value">{capitalizeFirstLetter(event.tag.action)}</div>
                </div>
                <div className="data-info">
                    <div className="data-info-title">Timestamp</div>
                    <div className="data-info-value">{getDateFormat(event.wall_clock_time, true)}</div>
                </div>
                <div className="data-info">
                    <div className="data-info-title">Match minute</div>
                    <div className="data-info-value">{isShl? shlGameTime : gameTime}’</div>
                </div>
                {!isShl && (
                    <div className="data-info">
                        <div className="data-info-title">Match state</div>
                        <div className="data-info-value">{event.score}, {event.game_phase}</div>
                    </div>
                )}
                {!isShl && (
                    <div className="data-info">
                        <div className="data-info-title">Match kickoff</div>
                        <div className="data-info-value">{getDateFormat(game.start_of_1st_half, "withTime")}</div>
                    </div>
                )}
                <div className="data-info">
                    <div className="data-info-title">Public release</div>
                    <div className="data-info-value">{getDateFormat(game.public_release_timestamp, true)}</div>
                </div>
                {isFsAdmin && (
                    <div className="confirm-cancel-btn-cont right margin-top">
                        <button type="button" className="red-btn" onClick={() => setDeletingEvent(!deletingEvent)}>
                            <span><FaTrashAlt className="icon-in-btn"/> Delete Event</span>
                        </button>
                    </div>
                )}
            </form>
            {deleteEventModal}
        </div>
    )
}

export default EventMetadataSection;