import React, {useState, useEffect} from "react"
import MatchTaggingPermission from "../Library/Match/MatchTaggingPermission";
import { useLoginSession } from "../../stores/loginSession";
import { useBackend } from "../../utility/Backend";
import { useTagging } from "../../stores/VideoTagging";
import { useCheckIsAdmin } from "../../utility/UserGroups";
import { capitalizeFirstLetter } from "../../utility/Utilities";
import { tagToIcon } from "../../utility/Utilities";
import { getDisplayDuration } from "../../utility/Utilities";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

export const paramTypeDict = {
    "offending player": "offending_player",
    "player awarded": "player_awarded",
    "assist by": "assist_by",
    "shot type": "shot_type",
    "shot result": "shot_result",
    "on target": "on_target",
    "after set piece": "after_set_piece",
    "player in": "player_in",
    "player out": "player_out",
}

export const tagNameDict = {
    "end of game": "endOfGame",
    "end phase": "endPhase",
    "free kick": "freeKick",
    "medical treatment": "medicalTreatment",
    "red card": "redCard",
    "start phase": "startPhase",
    "throw-in": "throwIn",
    "yellow card": "yellowCard",
}

// TODO this currently only english
export const generateEventTitle = {
    
    corner: (taggingState) => {
        let title = "Corner kick"
        const {team} = taggingState
        if (team) title += `, ${taggingState.team.name}.`
        return title
    },
    // TODO more to add
    endOfGame: () => {
        return "Match ended"
    },

    endPhase: (taggingState) => {
        const {phase} = taggingState
        let title = "Break"
        if (phase === "2nd half") title += " before extra time"
        if (phase === "1st overtime") title += " between extra time"
        if (phase === "2nd overtime") title += " before penalty kicks"
        if (phase === "penalties") title = "Penalty kicks"
        return title
    },

    freeKick: (taggingState) => {
        const {team} = taggingState
        let title = "Free kick"
        if (team) title += `, ${taggingState.team.name}.`
        return title
    },

    goal: (taggingState) => {
        let title = "Goal"
        const {team, scorer} = taggingState
        if (team) title += ` ${taggingState.team.name}!`
        if (scorer) title += ` Scored by ${taggingState.scorer.name}.`
        return title
    },

    offside: (taggingState) => {
        let title = "Offside"
        const {team, player} = taggingState
        if (player) title += `, ${taggingState.player.name}`
        if (team) title += `, ${taggingState.team.name}`
        if (player || team) title += "." 
        return title
    },

    penalty: (taggingState) => {
        let title = "Penalty"
        const {team} = taggingState
        if (team) title += `, ${taggingState.team.name}.`
        return title
    },

    redCard: (taggingState) => {
        const {team, player} = taggingState
        let title = "Red card"
        if (team) title += `, ${taggingState.team.name}`
        if (player) title += `: ${taggingState.player.name}.`
        return title
    },
    // TODO more to add
    shot: (taggingState) => {
        let title = "Shot"
        const {team, player, on_target, shot_type, shot_result} = taggingState
        
        if (shot_type === "header") title = "Heading"
        if (shot_type === "free kick") title = "Free kick"

        if (shot_type === "penalty") {
            if (shot_result) title = "Penalty miss"
            else title = "Penalty"
        }

        if (on_target === "yes") {
            title += " on goal"
        } else if (!on_target && shot_type !== "penalty") {
            if (shot_result === "wide") title += " wide"
            if (shot_result === "saved") title = `Saved ${title}`
            if (shot_result === "blocked") title = `Blocked ${title}`
            if (shot_result === "post") title += " in post"
            if (shot_result === "bar") title += " in bar"
        }

        if (player) title += ` by ${taggingState.player.name}`
        if (team) title += `, ${taggingState.team.name}`
        if (player || team) title += "." 
        return title
    },

    startPhase: (taggingState) => {
        const {phase} = taggingState
        let title = "Kick off"
        if (phase === "1st half") title += " first half"
        if (phase === "2nd half") title += " second half"
        if (phase === "1st overtime") title += " first extra time"
        if (phase === "2nd overtime") title += " second extra time"
        if (phase === "penalties") title = "Penalty"
        return title
    },

    substitution: (taggingState) => {
        const {team, player_in, player_out} = taggingState
        let title = "Substitution"
        if (team) title += ` ${taggingState.team.name}`
        if (player_out) title += `, ${player_out.name} out`
        if (player_in) title += `, ${player_in.name} in`
        if (player_in || player_out) title += "."
        return title
    },

    throwIn: (taggingState) => {
        const {team} = taggingState
        let title = "Throw in"
        if (team) title += `, ${taggingState.team.name}.`
        return title
    },

    yellowCard: (taggingState) => {
        const {team, player} = taggingState
        let title = "Yellow card"
        if (team) title += `, ${taggingState.team.name}`
        if (player) title += `: ${taggingState.player.name}.`
        return title
    }
}

export function TagsOptions ({game, taggingState, taggingDispatch, playlist=null, taggingTimestamp}) {

    const {taggingProps, cancelTaggingProps} = useTagging()
    const {token} = useLoginSession()
    const query = {access_token: token}
    const navigate = useNavigate()

    const masterAssetId = playlist?.master_videoasset?.id || null
    
    const permissionPath = masterAssetId ? "/asset/" + masterAssetId + "/perms" : "/game/" + game.id + "/perms"
    const {data: taggingPermission} = useBackend(permissionPath, query)

    if (!taggingPermission) return null

    const tagEvents = taggingPermission.tagging.allowed_actions
    tagEvents.sort((a,b) => a.localeCompare(b))
    
    if (tagEvents.length === 0) return (
        <div className="tags-options-cont no-tags">
            No available tags
        </div>
    )

    // back to the original match page and clear local storage
    const closeTagging = () => {
        navigate("/library/match/" + taggingProps.gameId)
        cancelTaggingProps()
    }

    // TODO make two separate functions for tagging asset and game
    const onSelectTag = (tag) => {
        if (taggingState.tag === tag) return
        if (!taggingState.time) taggingDispatch({type: "time", payload: new Date()})
        taggingDispatch({type: "tag", payload: tag})

        const currentTimeMillSec = taggingTimestamp
        const fromTimestamp = Math.max(0, currentTimeMillSec - 15000)
        const toTimestamp = currentTimeMillSec + 20000
        taggingDispatch({type: "video_offset", payload: currentTimeMillSec})
        taggingDispatch({type: "from_timestamp", payload: fromTimestamp})
        taggingDispatch({type: "to_timestamp", payload: toTimestamp})
    }

    return (
        <div className="tags-options-cont">
            <div className="tagging-section-title">
                Events
            </div>
            <div className="tagging-tags-options">
                {tagEvents.map((t) => {
                    return (
                        <div 
                            key={t}
                            onClick={() => onSelectTag(t)}
                            className={classNames("tag-single", {
                                "active": taggingState.tag === t,
                                "fan-tagging": t === "fan tagging"
                            })}>
                            {tagToIcon(t)}
                            {capitalizeFirstLetter(t)}
                        </div>
                    )
                })}
            </div>
            <div className="select-tag-to-start">Select an event to tag</div>
            <button onClick={closeTagging} className="cancel-tagging-btn">Close Tagging</button>
        </div>
    )
}

export function LiveTimeTagging () {

    const [time, setTime] = useState(new Date())

    useEffect(() => {
        const liveTime = setInterval(() => {
            setTime(new Date())
        }, 1000)
        return () => clearInterval(liveTime)
    }, [])

    const hours = time.getHours()
    const minutes = time.getMinutes()
    const seconds = time.getSeconds()

    return (
        <div className="tagging-live-time-cont">
            <div className="tagging-live-time">{hours <= 9 ? `0${hours}`: hours}</div>
            <div>:</div>
            <div className="tagging-live-time">{minutes <= 9 ? `0${minutes}`: minutes}</div>
            <div>:</div>
            <div className="tagging-live-time">{seconds <= 9 ? `0${seconds}`: seconds}</div>
        </div>
    )
}

export function useGetPassedTaggingTime (taggingTime) {

    const [timePassed, setTimePassed] = useState(null)

    useEffect(() => {
        const timePassed = new Date().getTime() - new Date(taggingTime).getTime()
        setTimePassed(timePassed)
        const countTime = setInterval(() => {
            const timePassed = new Date().getTime() - new Date(taggingTime).getTime()
            setTimePassed(timePassed)
        }, 1000)
        return () => clearInterval(countTime)
    }, [taggingTime])

    return timePassed
}

export function DisplayPassedTaggingTime ({taggingTime}) {
    const timePassed = useGetPassedTaggingTime(taggingTime)
    return (
        <div className="tagged-time-passed">{getDisplayDuration(timePassed)} ago</div>
    )
}

export function AdjustTaggingTime ({taggingState, taggingDispatch}) {

    const onAdjustTaggedTime = (sec) => {
        const currentSecond = new Date(taggingState.time).getSeconds()
        const updatedTime = new Date(taggingState.time).setSeconds(currentSecond + sec)
        taggingDispatch({type: "time", payload: updatedTime})
    }

    const timePassed = useGetPassedTaggingTime(taggingState.time)

    return (
        <div className="tag-details-single time">
            <div className="tag-details-title">Adjust time</div>
            <div className="tagging-time-adjustment">
                <div className="time-adjust-btn-cont">
                    <button onClick={() => onAdjustTaggedTime(-20)} className="time-adjust-btn">- 20 sec</button>
                    <button onClick={() => onAdjustTaggedTime(-5)} className="time-adjust-btn">- 5 sec</button>
                    <button onClick={() => onAdjustTaggedTime(5)} disabled={timePassed <= 5000} className="time-adjust-btn">+ 5 sec</button>
                    <button onClick={() => onAdjustTaggedTime(20)} disabled={timePassed <= 20000} className="time-adjust-btn">+ 20 sec</button>
                </div>
                <button onClick={() => taggingDispatch({type: "time", payload: new Date()})} className="time-adjust-btn">Now</button>
            </div>
        </div>
    )
}

export function getCompleteTagParams (taggingState, tagParam, forPlaylist=false) {

    const getParamValue = (paramType, paramValue) => {
        // team and player need to send id and the name
        if (paramType === "team" || paramType === "player") {
            return {
                id: paramValue.id,
                type: paramType,
                value: paramValue.name,
            }
        }
        else return {
            type: paramType,
            value: paramValue,
        }
    }

    const paramProps = {
        action: taggingState.tag,
    }

    for (let i = 0; i < tagParam.length; i++) {
        const paramNameInState = paramTypeDict[tagParam[i].name] || tagParam[i].name
        // check first if the param has value in the tagging state
        if (!!taggingState[paramNameInState]) {
            paramProps[tagParam[i].name] = getParamValue(tagParam[i].type, taggingState[paramNameInState])
        }
    }
    
    // tags for playlist must be an array
    return forPlaylist ? [paramProps] : paramProps
}

export function MatchTaggingButton ({game}) {
    
    const {token} = useLoginSession()
    const query = {access_token: token}
    const {tagVideo} = useTagging()
    const [,isAdmin] = useCheckIsAdmin()

    const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false)
    
    const {data: gameTaggingPermission} = useBackend("/game/" + game.id + "/perms", query)

    // const gameIsLive = game.has_live_playlist
    const gameIsLive = true
    const userHasGameTagPermission = isAdmin || gameTaggingPermission?.tag || false
    const userHasGameGrantPermission = isAdmin || gameTaggingPermission?.grant || false
    
    if (!userHasGameTagPermission && !userHasGameGrantPermission) return null

    const onTagGame = () => {
        tagVideo("game", game.id)
    }

    return (
        <div className="match-tagging-btn">
            {(gameIsLive && userHasGameTagPermission) && (
                <button onClick={onTagGame} className="tag-video-btn smaller-font-btn">Tag new event</button>
            )}
            {userHasGameGrantPermission && (
                <button onClick={() => setIsPermissionModalOpen(true)} className="smaller-font-btn">Tagging permission</button>
            )}
            {isPermissionModalOpen && (
                <MatchTaggingPermission
                    game={game}
                    onClose={() => setIsPermissionModalOpen(false)}/>
            )}
        </div>
    )
}

export function AssetTaggingButton ({playlist}) {

    const {tagVideo} = useTagging()
    const [,isAdmin] = useCheckIsAdmin()
    
    const masterAssetId = playlist?.master_videoasset?.id
    
    const {data: assetTaggingPermission} = useBackend(masterAssetId ? `/asset/${masterAssetId}/perms` : null, {})
    
    const userHasAssetTagPermission = isAdmin || assetTaggingPermission?.tag || false

    if (!playlist?.game) return null
    if (!userHasAssetTagPermission) return null

    const onTagVideo = (e) => {
        e.stopPropagation()
        tagVideo("asset", playlist.game.id)
    }

    return (
        <div className="asset-tag-video">
            <button onClick={onTagVideo} className="tag-video-btn smaller-font-btn">Tag new event</button>
        </div>
    )
}

// event title in norwegian
// export const generateEventTitle = {
    
//     corner: (taggingState) => {
//         let title = "Hjørnespark"
//         const {team} = taggingState
//         if (team) title += `, ${taggingState.team.name}.`
//         return title
//     },
//     // TODO more to add
//     endOfGame: () => {
//         return "Kampen over"
//     },

//     endPhase: (taggingState) => {
//         const {phase} = taggingState
//         let title = "Pause"
//         if (phase === "2nd half") title += " før ekstraomganger"
//         if (phase === "1st overtime") title += " mellom ekstraomganger"
//         if (phase === "2nd overtime") title += " før straffesparkkonkurranse"
//         if (phase === "penalties") title = "Straffesparkkonkurranse"
//         return title
//     },

//     freeKick: (taggingState) => {
//         const {team} = taggingState
//         let title = "Frispark"
//         if (team) title += `, ${taggingState.team.name}.`
//         return title
//     },

//     goal: (taggingState) => {
//         let title = "Mål"
//         const {team, scorer} = taggingState
//         if (team) title += ` ${taggingState.team.name}!`
//         if (scorer) title += ` Scoring av ${taggingState.scorer.name}.`
//         return title
//     },

//     offside: (taggingState) => {
//         let title = "Offside"
//         const {team, player} = taggingState
//         if (player) title += `, ${taggingState.player.name}`
//         if (team) title += `, ${taggingState.team.name}`
//         if (player || team) title += "." 
//         return title
//     },

//     penalty: (taggingState) => {
//         let title = "Straffespark"
//         const {team} = taggingState
//         if (team) title += `, ${taggingState.team.name}.`
//         return title
//     },

//     redCard: (taggingState) => {
//         const {team, player} = taggingState
//         let title = "Rødt kort"
//         if (team) title += `, ${taggingState.team.name}`
//         if (player) title += `: ${taggingState.player.name}.`
//         return title
//     },
//     // TODO more to add
//     shot: (taggingState) => {
//         let title = "Skudd"
//         const {team, player, on_target, shot_type, shot_result} = taggingState
        
//         if (shot_type === "header") title = "Heading"
//         if (shot_type === "free kick") title = "Frispark"

//         if (shot_type === "penalty") {
//             if (shot_result) title = "Straffebom"
//             else title = "Straffespark"
//         }

//         if (on_target === "yes") {
//             title += " på mål"
//         } else if (!on_target && shot_type !== "penalty") {
//             if (shot_result === "wide") title += " utenfor"
//             if (shot_result === "saved") title = `Reddet ${title}`
//             if (shot_result === "blocked") title = `Blockert ${title}`
//             if (shot_result === "post") title += " i stolpen"
//             if (shot_result === "bar") title += " i tverrligeren"
//         }

//         if (player) title += ` av ${taggingState.player.name}`
//         if (team) title += `, ${taggingState.team.name}`
//         if (player || team) title += "." 
//         return title
//     },

//     startPhase: (taggingState) => {
//         const {phase} = taggingState
//         let title = "Avspark"
//         if (phase === "1st half") title += " første omgang"
//         if (phase === "2nd half") title += " andre omgang"
//         if (phase === "1st overtime") title += " første ekstraomgang"
//         if (phase === "2nd overtime") title += " andre ekstraomgang"
//         if (phase === "penalties") title = "Straffesparkkonkurranse"
//         return title
//     },

//     substitution: (taggingState) => {
//         const {team, player_in, player_out} = taggingState
//         let title = "Bytte"
//         if (team) title += ` ${taggingState.team.name}`
//         if (player_out) title += `, ${player_out.name} ut`
//         if (player_in) title += `, ${player_in.name} inn`
//         if (player_in || player_out) title += "."
//         return title
//     },

//     throwIn: (taggingState) => {
//         const {team} = taggingState
//         let title = "Innkast"
//         if (team) title += `, ${taggingState.team.name}.`
//         return title
//     },

//     yellowCard: (taggingState) => {
//         const {team, player} = taggingState
//         let title = "Gult kort"
//         if (team) title += `, ${taggingState.team.name}`
//         if (player) title += `: ${taggingState.player.name}.`
//         return title
//     }
// }