import React, { useReducer } from "react"
import { useBackend } from "../../utility/Backend";
import { useTagging } from "../../stores/VideoTagging";
import { useUpdateSearchParams } from "../../utility/Utilities";
import { VideoPlayerProvider } from "../../components/VideoPlayer/VideoPlayerProvider";
import "./Tagging.css"
import Tagging from "./Tagging";
import LoadingScreen from "../../components/LoadingScreen";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import { DualPage } from "../../components/Page";
import Page from "../../components/Page";
import { Helmet } from "react-helmet-async";

export const defaultForm = {
    is_private: true,
    description: "",
    tag: null,
    video_offset: null,
    from_timestamp: null,
    to_timestamp: null,
    thumbnail_url: null,
    // tag params
    time: null,
    team: null,
    player: null,
    phase: null,
    offending_player: null,
    player_awarded: null,
    scorer: null,
    assist_by: null,
    shot_type: null,
    shot_result: null,
    keeper: null,
    on_target: null,
    after_set_piece: null,
    reason: null,
    player_in: null,
    player_out: null,
}

export function formReducer (state, action) {
    switch (action.type) {
        case "reset": return defaultForm
        case "clear params": return {
            ...state,
            team: null,
            player: null,
            phase: null,
            offending_player: null,
            player_awarded: null,
            scorer: null,
            assist_by: null,
            shot_type: null,
            shot_result: null,
            keeper: null,
            on_target: null,
            after_set_piece: null,
            reason: null,
            player_in: null,
            player_out: null,
        }
        case "clear players": return {
            ...state,
            player: null,
            offending_player: null,
            player_awarded: null,
            scorer: null,
            assist_by: null,
            keeper: null,
            player_in: null,
            player_out: null,
        }
        default: {
            if (state.hasOwnProperty(action.type))
                return {...state, [action.type]: action.payload}
            return state
        }
    }
}

function TaggingPage () {

    const [searchParams,] = useUpdateSearchParams();
    const {taggingProps} = useTagging()
    const [taggingState, taggingDispatch] = useReducer(formReducer, defaultForm)
    
    // gameId on the url
    const gameParam = searchParams.get("game")
    // gameId that is saved in the local storage for tagging
    const selectedGameToTag = taggingProps?.gameId?.toString()
    // check if the gameId url and gameId in local storage are the same. if yes, then fetch the game playlist
    const gameForTagging = gameParam === selectedGameToTag ? selectedGameToTag : null
    
    const isTaggingAsset = taggingProps.type === "asset"
    const isTaggingGame = taggingProps.type === "game"

    // tags
    const {data: tagsData} = useBackend("/tag", undefined, {revalidateIfStale: false, revalidateOnFocus: false});
    // playlist
    const {data: gamePlaylist} = useBackend(gameForTagging ? ("/game/" + gameForTagging + "/playlists") : null, {
        filters: "full_game",
        access_token: "",
    }, {})

    const playlist = gamePlaylist?.playlists[0] || []
    const game = playlist.game

    if (isTaggingAsset && !tagsData) return <LoadingScreen/>
    
    // if user tries to open tagging page by typing the url, and it's not an ongoing tagging
    if (!game || playlist.length === 0) return (
        <Page>
            <div className="empty-tagging-page">Select asset or game to start tagging</div>
        </Page>
    )

    const taggingPageTitle = `Tagging - ${game.home_team.name} vs ${game.visiting_team.name}`

    return (
        <VideoPlayerProvider playlist={playlist} url={playlist.video_url} autoPlay={playlist?.is_live}>
            <DualPage withLayoutSelection defaultLayout={0}>
                <Page title={taggingPageTitle}>
                    <Helmet>
                        <title>Tagging</title>
                    </Helmet>
                    <Tagging
                        taggingState={taggingState} 
                        taggingDispatch={taggingDispatch} 
                        tagsData={tagsData}  
                        playlist={playlist}
                        isTaggingGame={isTaggingGame}
                        />
                </Page>
                {isTaggingAsset && (
                    <Page title="Video">
                        <div className="tagging-main">
                            {game.has_live_playlist && (
                                <div className="tagging-video-live">
                                    <div className="live-banner">LIVE</div>
                                </div>
                            )}
                            <VideoPlayer />
                        </div>
                    </Page>
                )}
            </DualPage>
        </VideoPlayerProvider>
    )
}

export default TaggingPage