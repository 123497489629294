const teamIdToTeamPageSuffix = {
    1:  "afceskilstuna/",
    2:  "aik/",
    3:  "bkhacken/",
    4:  "djurgardensif/",
    6:  "gefleif/",
    7:  "gifsundsvall/",
    8:  "halmstadsbk/",
    9:  "hammarbyif/",
    10: "helsingborgsif/",
    11: "ifelfsborg/",
    12: "ifkgoteborg/",
    13: "ifknorrkoping/",
    14: "iksirius/",
    15: "kalmarff/",
    16: "malmoff/",
    17: "orebrosk/",
    19: "jonkopingssodra/",
    20: "ostersundsfk/",
    23: "trelleborgsff/",
    24: "dalkurdff/",
    25: "ifbrommapojkarna/",
    26: "ikbrage/",
    27: "mjallbyaif/",
    28: "varbergsbois/",
    29: "degerforsif/",
    31: "ostersif/",
    32: "norrbyif/",
    33: "gais/",
    34: "ifkvarnamo/",
    36: "orgryteis/",
    39: "landskronabois/",
    48: "vasterassk/",
    53: "skovdeaik/",
    54: "utsiktensbk/",
    55: "ikoddevold/",
    56: "sandvikensif/",
}

export function channelToTeamPageSuffix (channel) {
    const teamId = channel?.team?.id
    if (!teamId) return ""
    return teamIdToTeamPageSuffix[teamId] || ""
}